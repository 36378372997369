import { Location } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { BarcodeFormat } from '@zxing/library';

@Component({
  selector: 'qrcode-reader',
  templateUrl: './qrcode-reader.component.html',
  styleUrls: ['./qrcode-reader.component.scss']
})
export class QrcodeReaderComponent {
  @Input() title: string;
  @Input() icon: string;
  @Output() scanQrcode: EventEmitter<string> = new EventEmitter<string>();

  allowedFormats = [ BarcodeFormat.QR_CODE ];
  resultReadQrCode = null;
  torch = false;
  hasCompatible: boolean;

  constructor(
    private readonly location: Location,
    private readonly toastr: ToastrService
  ) { }

  handleTorchCompatible(event: boolean): void {
    this.hasCompatible = event;
  }

  async handleClickFlashlight() {
    this.torch = !this.torch;
  }

  returnQrcode(): void {
    this.location.back();
  }

  handleScanSuccess(event): void {
    this.scanQrcode.emit(event);
  }

  handleScanError(): void {
    this.toastr.show('Ocorreu uma falha ao tentar realizar a leitura do QR Code. Deslize a barra para digitar o código, ou tente novamente.', 'Falha ao ler QR Code', {
      timeOut: 5000,
      messageClass: 'error',
      titleClass: JSON.stringify({
        isHour: true
      })
    });
    this.returnQrcode();
  }
}
