import { EventEmitter, Injectable } from '@angular/core';
import moment from 'moment';
import { Observable, of } from 'rxjs';
import { HttpService } from 'src/app/service/http/http.service';

@Injectable({
  providedIn: 'root'
})
export class FilterPeriodService {
  private readonly endpointPostFilter = 'performance-analysis/filterByPeriod';
  changePeriod: EventEmitter<string> = new EventEmitter();

  constructor(
    private readonly httpService: HttpService
  ) { }

  getPeriod() {
    return localStorage.getItem('datePeriod');
  }

  clearPeriod(): void {
    sessionStorage.removeItem('skuComparator');
    localStorage.removeItem('histogramRouteTime');
    sessionStorage.removeItem('dynamicGoals');
    localStorage.setItem('datePeriod', JSON.stringify({
      startDate: null,
      endDate: null
    }));
    this.changePeriod.emit(undefined);
  }

  setPeriod(period: string): void {
    sessionStorage.removeItem('skuComparator');
    localStorage.removeItem('histogramRouteTime');
    sessionStorage.removeItem('dynamicGoals');
    localStorage.setItem('datePeriod', period);
    this.changePeriod.emit(period);
  }

  postPeriod(period): Observable<any> {
    const body = {
      start_date: moment.parseZone(new Date(period?.startDate)).subtract(3, 'hours').unix() * 1000,
      end_date: moment.parseZone(new Date(period?.endDate)).subtract(3, 'hours').unix() * 1000
    }
    return this.httpService.genericPost(this.endpointPostFilter, body, {});
  }
}
