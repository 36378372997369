import { AfterContentInit, AfterViewInit, ChangeDetectorRef, Component, ContentChildren, ElementRef, EventEmitter, Input, Output, QueryList, ViewChild } from '@angular/core';
import { DividerComponent } from '../divider/divider.component';
import { TabInduxtryComponent } from './tab-induxtry/tab-induxtry.component';

@Component({
  selector: 'tabs-induxtry',
  templateUrl: './tabs-induxtry.component.html',
  styleUrls: ['./tabs-induxtry.component.scss']
})
export class TabsInduxtryComponent implements AfterContentInit, AfterViewInit {

  @ContentChildren(TabInduxtryComponent) tabs: QueryList<TabInduxtryComponent>;
  @ViewChild('divider') divider: DividerComponent;
  @ViewChild('tabContainer') tabContainer: ElementRef;

  @Input() showNav: boolean;
  firstItem: number;
  lastItem: number;
  afterPage = false;
  beforePage = false;

  @Output() onChange: EventEmitter<any> = new EventEmitter<any>();
  ngAfterContentInit(): void {
    this.tabs?.toArray().forEach((item,index) => {
      if(item.active){
        this.selectTab(item)
      }
    })
    const activeTabs = this.tabs.filter(tab => tab.active);
    if(activeTabs.length === 0) {
      this.selectTab(this.tabs.first);
    } else {
      this.validActiveTabs();
      this.onChange.emit(activeTabs[0]);
    }
  }

  constructor(
    private readonly el: ElementRef,
    private readonly cdr: ChangeDetectorRef
  ){}

  ngAfterViewInit() {
    const tabContainer = this.el.nativeElement.querySelector('.container-tab');
    const hasVerticalScroll = tabContainer.scrollWidth > tabContainer.clientWidth;
    if (hasVerticalScroll) {
      this.showNav = true;
    } else {
      this.showNav = false;
    }
    this.cdr.detectChanges();
  }

  selectTab(tab) {
    this.tabs.toArray().forEach(tabSelect => (tabSelect.active = false));
    tab.active = true;
    this.onChange.emit(tab);

    this.updateList(tab)

  }

  validActiveTabs() {
    const activeTabs = this.tabs.filter(tab => tab.active);

    if(activeTabs.length > 1) {
      activeTabs.slice(1).forEach(tab => {
        tab.active = false;
      })
    }
  }

  scrollTo(direction: 'left' | 'right') {
    const container = this.tabContainer.nativeElement;

    if (direction === 'left') {
      container.scrollLeft -= 100;
    } else if (direction === 'right') {
      container.scrollLeft += 100;
    }
  }


  updateList(tab){
    let start = false;
    let end = false;
    let i = 0;
    let j = this.tabs.toArray().length -1;
    while(start === false){
      if(this.tabs.toArray()[i]['disabled'] === true) {
        i++;
      }else if(this.tabs.toArray()[i]['disabled'] === false){
        this.firstItem = i;
        start = true;
      }
    }

    while(end === false) {
      if(this.tabs.toArray()[j]['disabled'] === true){
        j--;
      } else if(this.tabs.toArray()[j]['disabled'] === false){
        this.lastItem = j;
        end = true;
      }
    }

    const currentPosition = this.tabs.toArray().findIndex(tabValue => tabValue === tab);
    if(currentPosition === this.lastItem){
      this.afterPage = true;
      this.beforePage = false;
    } else if(currentPosition === this.firstItem){
      this.afterPage = false;
      this.beforePage = true;
    } else{
      this.afterPage = false;
      this.beforePage = false;
    }


  }

  scrollToTab(index: number) {
    const container = this.tabContainer.nativeElement;
    const selectedButton = container.querySelectorAll('.tab-button')[index];
    if (selectedButton) {
      const containerScrollLeft = container.scrollLeft;
      const containerWidth = container.clientWidth;
      const buttonWidth = selectedButton.clientWidth;
      const buttonLeft = selectedButton.offsetLeft;
      const buttonRight = buttonLeft + buttonWidth;

      if (buttonLeft < containerScrollLeft) {
        container.scrollLeft = buttonLeft - containerWidth;
      } else if (buttonRight > containerScrollLeft + containerWidth) {
        container.scrollLeft = buttonRight - containerWidth;
      }
    }
  }


}
