import { DragDropDirective } from './directives/drag-drop/drag-drop.directive';
import { NgModule } from '@angular/core';
import { CommonModule, DatePipe, DecimalPipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// Material
import { LayoutModule } from '@angular/cdk/layout';
import { MatButtonModule } from '@angular/material/button';
import { MatRadioModule } from '@angular/material/radio';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatTreeModule } from '@angular/material/tree';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatRippleModule, MatNativeDateModule } from '@angular/material/core';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatSelectModule } from '@angular/material/select';
import { MatTabsModule } from '@angular/material/tabs';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTableModule } from '@angular/material/table';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { MatSelectInfiniteScrollModule } from 'ng-mat-select-infinite-scroll';
import { ToastrModule } from 'ngx-toastr';

import { NgxMatDatetimePickerModule, NgxMatTimepickerModule, NgxMatNativeDateModule } from '@angular-material-components/datetime-picker';
import {NgxMaterialTimepickerModule} from 'ngx-material-timepicker';
import { NgxMaskModule } from 'ngx-mask';
import { ModalReorderComponent } from './components/dialogs/modal-reorder/modal-reorder.component'
import { MatTooltipModule } from '@angular/material/tooltip';

import { DoubleScrollComponent } from './components/scrolls/app-double-scroll/app-double-scroll.component';
import { PopoverComponent } from './components/popover/popover.component';
import { FilterTableComponent } from './components/tables/filter-table/filter-table.component';
import { ModalLoadingSecComponent } from './components/loads/modal-loading-sec/modal-loading-sec.component';

import { SwiperModule } from 'swiper/angular';


//Formulário
import { TimePickerComponent } from './components/inputs/time-picker/time-picker.component';
import { TextInputComponent } from './components/inputs/text-input/text-input.component';
import { PasswordInputComponent } from './components/inputs/password-input/password-input.component';
import { SelectInputComponent } from './components/inputs/select-input/select-input.component';
import { MultiselectInputComponent } from './components/inputs/multiselect-input/multiselect-input.component';
import { TimeInputComponent } from './components/inputs/time-input/time-input.component';
import { HourSuffixDirective } from './directives/mat-form-field/hour-suffix-directive';
import { DatapickerInputComponent } from './components/inputs/datapicker-input/datapicker-input.component';
import { FileInputComponent } from './components/inputs/file-input/file-input.component';
import { SuffixInputComponent } from './components/inputs/suffix-input/suffix-input.component';
import { NumberInputComponent } from './components/inputs/number-input/number-input.component';
import { PrefixInputComponent } from './components/inputs/prefix-input/prefix-input.component';
import { TextareaInputComponent } from './components/inputs/textarea-input/textarea-input.component';
import { ButtonComponent } from './components/inputs/button/button.component';
import { SelectInfiniteScrollSearchComponent } from './components/scrolls/select-infinite-scroll-search/select-infinite-scroll-search.component';
import { CheckboxComponent } from './components/inputs/checkbox/checkbox.component';
import { SwitchComponent } from './components/inputs/switch/switch.component';
import { RadioButtonComponent } from './components/inputs/radio-button/radio-button.component';

 //Estrutura e nav
import { TabsInduxtryComponent } from './components/common/tabs-induxtry/tabs-induxtry.component';
import { TabInduxtryComponent } from './components/common/tabs-induxtry/tab-induxtry/tab-induxtry.component';
import { PageSelectComponent } from './components/common/page-select/page-select.component';
import { DividerComponent } from './components/common/divider/divider.component';
import { CardsComponent } from './components/common/cards/cards.component';
import { ListaComponent } from './components/common/lista/lista.component';
import { LinksComponent } from './components/common/links/links.component';
import { CheckListComponent } from './components/common/check-list/check-list.component';
import { EmptyStateComponent } from './components/common/empty-state/empty-state.component';
import { DynamicTableComponent } from './components/tables/dynamic-table/dynamic-table.component';
import { DynamicSubTableComponent } from './components/tables/dynamic-sub-table/dynamic-sub-table.component';
import { AccordionInduxtryComponent } from './components/common/accordion-induxtry/accordion-induxtry.component';
import { ModalInduxtryComponent } from './components/common/modal-induxtry/modal-induxtry.component';
import { TreeInduxtryComponent } from './components/common/tree/tree.component';
import { TimeLineComponent } from './components/common/timeline/timeline.component';

//Status e Feedback
import { SwalComponent } from './components/dialogs/swal/swal.component';
import { LoadingComponent } from './components/loads/loading/loading.component';
import { MytoastrComponent } from './components/dialogs/mytoastr/mytoastr.component';
import { InlineNotificationComponent } from './components/dialogs/inline-notification/inline-notification.component';

 //Miscelânea
import { AvatarComponent } from './components/miscelanea/avatar/avatar.component';
import { TagsComponent } from './components/miscelanea/tags/tags.component';
import { ChipsComponent } from './components/miscelanea/chips/chips.component';
import { TooltipComponent } from './components/miscelanea/tooltip/tooltip.component';
import { TooltipMultComponent } from './components/miscelanea/tooltip-mult/tooltip-mult.component';

//Gráfico
import { ChartComponent } from './components/graphics/chart/chart.component';
import { GraphicPlotlyComponent } from './components/graphics/graphic-plotly/graphic-plotly.component';
import { GraphicTradingViewComponent } from './components/graphics/graphic-tradingView/graphic-tradingView.component';
import { MapBoxComponent } from './components/graphics/mapBox/mapBox.component';
import { LegendGraficComponent } from './components/common/legend-grafic/legend-grafic.component';

//Navigation
import { NavigationComponent } from './components/navigation/navigation/navigation.component';
import { SidenavComponent } from './components/navigation/sidenav/sidenav.component';
import { NavigationBrandLogoComponent } from './components/navigation/navigation-brand-logo/navigation-brand-logo.component';
import { SidenavUserComponent } from './components/navigation/sidenav-user/sidenav-user.component';
import { SidenavMenuComponent } from './components/navigation/sidenav-menu/sidenav-menu.component';
import { SidenavBottomComponent } from './components/navigation/sidenav-bottom/sidenav-bottom.component';
import { TopnavbarUserComponent } from './components/navigation/topnavbar-user/topnavbar-user.component';
import { TopnavbarBreadcrumbComponent } from './components/navigation/topnavbar-breadcrumb/topnavbar-breadcrumb.component';
import { SidenavMobileControlComponent } from './components/navigation/sidenav-mobile-control/sidenav-mobile-control.component';
import { TopnavbarComponent } from './components/navigation/topnavbar/topnavbar.component';
import { MenuMobileComponent } from './components/navigation/menu-mobile/menu-mobile.component';


//Outros
import { ModalLoadingComponent } from './components/loads/modal-loading/modal-loading.component';


// import {

//   //Formulário
//   TimePickerComponent,
//   TextInputComponent,
//   PasswordInputComponent,
//   SelectInputComponent,
//   MultiselectInputComponent,
//   TimeInputComponent,
//   DatapickerInputComponent,
//   FileInputComponent,
//   SuffixInputComponent,
//   NumberInputComponent,
//   PrefixInputComponent,
//   TextareaInputComponent,
//   ButtonComponent,
//   SelectInfiniteScrollSearchComponent,
//   CheckboxComponent,
//   SwitchComponent,
//   RadioButtonComponent,
//   HourSuffixDirective,

//   //Estrutura e nav
//   TabsInduxtryComponent,
//   TabInduxtryComponent,
//   PageSelectComponent,
//   DividerComponent,
//   CardsComponent,
//   ListaComponent,
//   LinksComponent,
//   EmptyStateComponent,
//   DynamicTableComponent,
//   DynamicSubTableComponent,
//   AccordionInduxtryComponent,
//   ModalInduxtryComponent,
//   TreeInduxtryComponent,
//   CheckListComponent,

//   //Status e Feedback
//   SwalComponent,
//   LoadingComponent,
//   MytoastrComponent,

//   //Miscelânea
//   TagsComponent,
//   AvatarComponent,
//   ChipsComponent,
//   TooltipComponent,
//   TooltipMultComponent,

//   //Gráfico
//   ChartComponent,
//   GraphicPlotlyComponent,
//   GraphicTradingViewComponent,
//   MapBoxComponent,
//   LegendGraficComponent,

//   //Navigation
  // NavigationComponent,
//   SidenavComponent,
//   NavigationBrandLogoComponent,
//   SidenavUserComponent,
//   SidenavMenuComponent,
//   SidenavBottomComponent,
//   TopnavbarUserComponent,
//   TopnavbarBreadcrumbComponent,
//   SidenavMobileControlComponent,
//   TopnavbarComponent,
//   MenuMobileComponent,

//   ModalLoadingComponent,
//   DoubleScrollComponent,
//   PopoverComponent,
//   FilterTableComponent,
//   ModalLoadingSecComponent,

//   SharedModule as SharedPackageInduxtry
//  } from '@induxtry/library';

import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';


import { RouterModule } from '@angular/router';
import { ThemeService } from './service/theme';


import { MyToastService } from './service/myToast';
import { PlotlyService } from './components/graphics/graphic-plotly/services/plotly-service';
import { BannerComponent } from './components/dialogs/banner/banner.component';
import { TooltipModule } from './components/miscelanea/tooltip-induxtry/tooltip.module';
import { TopnavbarProfileComponent } from './components/navigation/topnavbar-profile/topnavbar-profile.component';
import { InsertTagsComponent } from './components/miscelanea/insert-tags/insert-tags.component';
import { TextMaskModule } from 'angular2-text-mask';
import { FlowchartComponent } from './components/common/flowchart/flowchart.component';
import { QrcodeReaderComponent } from './components/common/qrcode-reader/qrcode-reader.component';
import { ZXingScannerModule } from '@zxing/ngx-scanner';
import { TranslateLanguageComponent } from './components/common/translate-language/translate-language.component';
import { TranslateModule } from '@ngx-translate/core';
import { DashboardCardComponent } from './components/common/dashboard-card/dashboard-card.component';
import { MatDialogModule } from '@angular/material/dialog';
import { FilterPeriodComponent } from './components/filter-period/filter-period.component';
import { CalendarPeriodComponent } from './components/inputs/calendars/calendar-period/calendar-period.component';
import { CalendarComponent } from './components/inputs/calendars/calendar/calendar.component';
import { CalendarMonthComponent } from './components/inputs/calendars/calendar-month/calendar-month.component';
import { CalendarYearComponent } from './components/inputs/calendars/calendar-year/calendar-year.component';
import { HeaderPickerComponent } from './components/inputs/calendars/header-picker/header-picker.component';
import { CalendarPickerComponent } from './components/inputs/calendars/calendar-picker/calendar-picker.component';
import { AppliedFiltersComponent } from './components/applied-filters/applied-filters.component';

@NgModule({
  declarations: [
    ModalLoadingComponent,
    ModalLoadingSecComponent,
    FilterTableComponent,
    PopoverComponent,
    ModalReorderComponent,
    DoubleScrollComponent,

    //Formulário
    TimePickerComponent,
    TextInputComponent,
    PasswordInputComponent,
    SelectInputComponent,
    MultiselectInputComponent,
    TimeInputComponent,
    DatapickerInputComponent,
    FileInputComponent,
    SuffixInputComponent,
    NumberInputComponent,
    PrefixInputComponent,
    TextareaInputComponent,
    ButtonComponent,
    SelectInfiniteScrollSearchComponent,
    CheckboxComponent,
    SwitchComponent,
    RadioButtonComponent,
    DragDropDirective,

    //Estrutura e nav
    TabsInduxtryComponent,
    TabInduxtryComponent,
    PageSelectComponent,
    DividerComponent,
    CardsComponent,
    ListaComponent,
    LinksComponent,
    CheckListComponent,
    EmptyStateComponent,
    DynamicTableComponent,
    DynamicSubTableComponent,
    AccordionInduxtryComponent,
    ModalInduxtryComponent,
    TreeInduxtryComponent,
    TimeLineComponent,
    FlowchartComponent,
    DashboardCardComponent,
    //-------------

    //Status e Feedback
    SwalComponent,
    LoadingComponent,
    MytoastrComponent,
    InlineNotificationComponent,
    BannerComponent,

    //Miscelânea
    AvatarComponent,
    TagsComponent,
    InsertTagsComponent,
    ChipsComponent,
    TooltipComponent,
    TooltipMultComponent,
    //-----------

    //Gráfico
    ChartComponent,
    GraphicPlotlyComponent,
    GraphicTradingViewComponent,
    MapBoxComponent,
    LegendGraficComponent,


    //Navigation
    NavigationComponent,
    SidenavComponent,
    NavigationBrandLogoComponent,
    SidenavUserComponent,
    SidenavMenuComponent,
    SidenavBottomComponent,
    TopnavbarUserComponent,
    TopnavbarBreadcrumbComponent,
    SidenavMobileControlComponent,
    TopnavbarComponent,
    MenuMobileComponent,

    // Pesquisa e filtros
    QrcodeReaderComponent,

    //Directives
    HourSuffixDirective,
    TopnavbarProfileComponent,
    TranslateLanguageComponent,
    FilterPeriodComponent,
    CalendarPeriodComponent,
    HeaderPickerComponent,
    CalendarPickerComponent,
    CalendarComponent,
    CalendarMonthComponent,
    CalendarYearComponent,
    AppliedFiltersComponent
  ],
  imports: [
    // SharedPackageInduxtry,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    // Material
    LayoutModule,
    MatToolbarModule,
    MatFormFieldModule,
    MatInputModule,
    MatNativeDateModule,
    MatButtonModule,
    MatRadioModule,
    MatSidenavModule,
    MatTreeModule,
    MatIconModule,
    MatListModule,
    MatCheckboxModule,
    MatExpansionModule,
    MatMenuModule,
    MatRippleModule,
    MatDatepickerModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSelectInfiniteScrollModule,
    MatSelectModule,
    MatTabsModule,
    MatTableModule,
    MatTooltipModule,
    NgxMatDatetimePickerModule,
    NgxMaterialTimepickerModule,
    NgxMatTimepickerModule,
    NgxMatNativeDateModule,
    NgxMatSelectSearchModule,
    TextMaskModule,
    ToastrModule.forRoot({
      toastComponent: MytoastrComponent, // added custom toast!
    }),
    NgxMaskModule.forRoot(),
    NgbModule,
    SwiperModule,
    TooltipModule,
    ZXingScannerModule,
    TranslateModule
  ],
  exports: [
    ZXingScannerModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    // Material
    LayoutModule,
    MatToolbarModule,
    MatNativeDateModule,
    MatButtonModule,
    MatRadioModule,
    MatFormFieldModule,
    MatSidenavModule,
    MatTreeModule,
    MatIconModule,
    MatListModule,
    MatExpansionModule,
    MatCheckboxModule,
    MatMenuModule,
    MatDatepickerModule,
    MatTabsModule,
    MatRippleModule,
    MatInputModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatTableModule,
    MatTooltipModule,
    MatSelectInfiniteScrollModule,
    MatDialogModule,
    ModalLoadingComponent,
    ModalLoadingSecComponent,
    FilterTableComponent,
    PopoverComponent,
    NgxMatDatetimePickerModule,
    NgxMaterialTimepickerModule,
    NgxMatTimepickerModule,
    NgxMatNativeDateModule,
    NgxMaskModule,
    DoubleScrollComponent,

    //Formulário
    TimePickerComponent,
    TextInputComponent,
    PasswordInputComponent,
    SelectInputComponent,
    MultiselectInputComponent,
    TimeInputComponent,
    HourSuffixDirective,
    DatapickerInputComponent,
    FileInputComponent,
    SuffixInputComponent,
    NumberInputComponent,
    PrefixInputComponent,
    TextareaInputComponent,
    ButtonComponent,
    SelectInfiniteScrollSearchComponent,
    CheckboxComponent,
    SwitchComponent,
    RadioButtonComponent,
    DragDropDirective,

    //Estrutura e nav
    TabsInduxtryComponent,
    TabInduxtryComponent,
    PageSelectComponent,
    DividerComponent,
    CardsComponent,
    ListaComponent,
    LinksComponent,
    CheckListComponent,
    EmptyStateComponent,
    DynamicTableComponent,
    DynamicSubTableComponent,
    AccordionInduxtryComponent,
    ModalInduxtryComponent,
    TreeInduxtryComponent,
    TimeLineComponent,
    FlowchartComponent,
    DashboardCardComponent,

    //Status e Feedback
    SwalComponent,
    LoadingComponent,
    MytoastrComponent,
    InlineNotificationComponent,
    BannerComponent,

    //Miscelânea
    AvatarComponent,
    TagsComponent,
    InsertTagsComponent,
    ChipsComponent,
    TooltipComponent,
    TooltipMultComponent,
    TooltipModule,

    //Gráfico
    ChartComponent,
    GraphicPlotlyComponent,
    GraphicTradingViewComponent,
    MapBoxComponent,
    LegendGraficComponent,

    // Pesquisa e filtros
    QrcodeReaderComponent,

    //Pacote Induxtry
    // SharedPackageInduxtry,

    NgxMatSelectSearchModule,


    //Navigation
    NavigationComponent,
    SidenavComponent,
    NavigationBrandLogoComponent,
    SidenavUserComponent,
    SidenavMenuComponent,
    SidenavBottomComponent,
    TopnavbarUserComponent,
    TopnavbarBreadcrumbComponent,
    SidenavMobileControlComponent,
    TopnavbarComponent,
    MenuMobileComponent,
    FilterPeriodComponent,
    AppliedFiltersComponent,
    CalendarPickerComponent,
    CalendarPeriodComponent,
    HeaderPickerComponent,
    CalendarComponent,
    CalendarMonthComponent,
    CalendarYearComponent,

    TranslateLanguageComponent,
    SwiperModule,
    TranslateModule
  ],
  providers: [
    DatePipe,
    DecimalPipe,
    ThemeService,
    MyToastService,
    PlotlyService
  ],
  entryComponents: [
    SwalComponent,
    MytoastrComponent,
    ModalLoadingComponent,
    ModalLoadingSecComponent,
    ModalReorderComponent
  ]
})
export class SharedModule {}
