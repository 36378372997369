import { NavigationService } from '../../../service/navigation.service';
import { DynamicSubTableDataSource } from './dynamic-sub-table-datasource';
import {
  AfterViewInit,
  Component,
  OnInit,
  OnDestroy,
  ViewChild,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  HostListener,
  ViewChildren,
  QueryList,
} from '@angular/core';
import { MatTable } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { DatePipe, DecimalPipe } from '@angular/common';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { DynamicTable } from '../../../../../utils/models/table.interface';
import { RequestAttribute } from '../../../../../utils/models/http.interface';
import { PageSelectComponent } from '../../common/page-select/page-select.component';
import moment from 'moment';
import { HttpService } from '../dynamic-table/service/httpService.service';


const NUMBER_ITENS_DEFAULT = 10;
@Component({
  selector: 'dynamic-subTable',
  templateUrl: './dynamic-sub-table.component.html',
  styleUrls: ['./dynamic-sub-table.component.scss'],
})
export class DynamicSubTableComponent
  implements AfterViewInit, OnInit, OnChanges, OnDestroy {

  private readonly _unsubscribe$: Subject<any> = new Subject();

  @ViewChildren('filterTable') filterTable;
  @ViewChild(MatTable, { static: true }) table: MatTable<any>;
  @ViewChild('pageSelect', { static: true }) pageSelect: PageSelectComponent;
  @ViewChildren('checkBox') checkBox: QueryList<any>;

  @Input() dynamicTable: DynamicTable;
  @Input() attributes: RequestAttribute[];
  @Input() manyItens = NUMBER_ITENS_DEFAULT;
  @Input() hasPaginate = true;
  @Input() externalData: boolean;
  @Input() values;
  @Input() showCheckbox = false;
  @Input() hasDelete = false;
  @Input() elementId;
  @Input() hasSort = true;
  @Input() expandedItem
  @Input() salveExpand = false
  @Input() indexSubTable = 0


  // Output Action
  @Output() edit: EventEmitter<any> = new EventEmitter();
  @Output() duplicate: EventEmitter<any> = new EventEmitter();
  @Output() delete: EventEmitter<any> = new EventEmitter();
  @Output() view: EventEmitter<any> = new EventEmitter();
  @Output() modalEdit: EventEmitter<any> = new EventEmitter();
  @Output() getEdit: EventEmitter<any> = new EventEmitter();
  @Output() idsOrdersSubTable: EventEmitter<any> = new EventEmitter();
  @Output() closedSubTable: EventEmitter<any> = new EventEmitter();

  // Output Uteis
  @Output() loadedTable: EventEmitter<any> = new EventEmitter();
  @Output() filter: EventEmitter<any> = new EventEmitter();
  @Output() orders: EventEmitter<any> = new EventEmitter();

  dataSourceSubTable: DynamicSubTableDataSource;
  _dynamicTableSubTable: DynamicTable;
  attributeParams: RequestAttribute[] = [];
  filterParams: RequestAttribute[] = [];

  data: any[] = [];
  editRow: boolean[] = [];
  checked = [];
  checks = new Array(NUMBER_ITENS_DEFAULT).fill(false);

  filterOptions = {};
  dateFormat = 'DD/MM/YYYY';
  dateHourFormat = 'DD/MM/YYYY - HH:mm';
  flagFilter = true;
  widthSubTable;
  dateForm: FormGroup;
  flagEditAll: boolean;
  expandedElement: any = null;
  ords = [];
  ids2 = [];
  ids = [];
  hasSomeFilter: boolean;
  filterActive = false;
  optionsSelect = [];

  constructor(
    private readonly decimalPipe: DecimalPipe,
    private readonly datePipe: DatePipe,
    private readonly formBuilder: FormBuilder,
    public dialog: MatDialog,
    public httpService: HttpService,
    private readonly nav: NavigationService,
  ) {
    this.dateForm = this.formBuilder.group({
      date: [null],
    });
  }

  @HostListener('window:resize', ['$event'])
  onResize(event): void {
    this.widthSubTable = event.target.innerWidth;
    this.reloadStickySubTable();
  }

  ngOnInit(): void {
    if (this.attributes !== undefined) {
      this.attributeParams = this.attributes;
    }

    this.hasSomeFilter = this._dynamicTableSubTable.displayedColumns.some(column => column.hasFilter === true);
    if (this.hasSomeFilter && this.expandedItem) {
      this.dataSourceSubTable.loadFilters(
        this._dynamicTableSubTable.endpoint,
        this._dynamicTableSubTable.requestParams,
        this.attributeParams
      );
      if (this.salveExpand) {
        this.loadTableSub(true)
      }
    }
  }

  openedSelectTable(selectInterfaceColumn): void {
    this.optionsSelect = [];
    this.httpService.genericGetSelect(selectInterfaceColumn).subscribe(response => {
      this.optionsSelect = response;
    })
  }

  ngAfterViewInit() {
    this.widthSubTable = window.innerWidth;

    this.dataSourceSubTable.filters$.subscribe((dataSub) => {
      this.setFilterOptionsSubTable(dataSub[0]);
    });

    this.dataSourceSubTable.data$.subscribe((dataSub: any) => {
      dataSub.forEach((itemSub, indexSub) => {
        if (this.checked.includes(itemSub.id)) {
          this.checks[indexSub] = true;
        } else {
          this.checks[indexSub] = false;
        }
      });

      if (dataSub.length) {
        let auxSub = '';
        dataSub.forEach((e) => {
          if (e.favoriteMachines) {
            e.favoriteMachines.forEach((f) => {
              auxSub += f + ',';
            });
            e.favoriteMachines = auxSub.replace(/,\s*$/, ' ');
            auxSub = '';
          }
        });

        if (dataSub[0]?.dates) {
          this._dynamicTableSubTable = JSON.parse(JSON.stringify(this.dynamicTable));
          dataSub.forEach((e, index) => {
            dataSub[index].dates.forEach((element, i) => {
              e[`dates${i}`] = element.value;
            });
          });

          const dynamicSubAux = this._dynamicTableSubTable.columns.pop();
          dataSub[0].dates.forEach((e, i) => {
            this._dynamicTableSubTable.columns.push(`dates${i}`);
            this._dynamicTableSubTable.displayedColumns.push({
              attribute: `dates${i}`,
              title: e.date,
              type: 'number',
              sticky: false,
              subtitle: {
                name: '',
                lock: true,
              },
            });
          });
          this._dynamicTableSubTable.columns.push(dynamicSubAux);
        }
      }
      this.data = dataSub;

      this.loadedTable.emit(dataSub);
      this.reloadStickySubTable();
      this.editRow = new Array(this.data.length).fill(false);
    });
  }

  ngOnChanges(): void {
    if (this.values['rows']) {
      this.dataSourceSubTable = new DynamicSubTableDataSource(this.httpService, this.dialog);
      this.dataSourceSubTable.setData(this.values, this.dynamicTable.endpoint);
    }

    if (this.hasSomeFilter && this.expandedItem) {
      this.dataSourceSubTable.loadFilters(
        this._dynamicTableSubTable.endpoint,
        this._dynamicTableSubTable.requestParams,
        this.attributeParams
      );
      if (this.salveExpand) {
        this.loadTableSub(true)
      }
    }
    if (this.dynamicTable) {
      this._dynamicTableSubTable = JSON.parse(JSON.stringify(this.dynamicTable));
    }
    if (!this.showCheckbox) {
      this.uncheckAll();
    }
    if (this.hasDelete) {
      this.ids = this.data.map(data => data.id)
    }
  }

  setFilterSub(filter) {
    this.attributeParams.forEach(att => {
      if (filter[att.param] !== undefined) {
        const index = filter[att.param].findIndex(filt => String(filt.value) === String(att.value))
        if (index > -1) {
          filter[att.param][index]['check'] = true
        }
      }
    })
  }

  setActivateFilter(filter, column) {
    if (
      this.filterOptions[filter['column']['attribute']] !== undefined &&
      this.filterOptions[filter['column']['attribute']].length > 0
    ) {
      const filterSelect = this.filterOptions[filter['column']['attribute']].filter(e => e.check)
      return filterSelect?.length
    }
  }

  ngOnDestroy(): void {
    this._unsubscribe$.next('');
    this._unsubscribe$.complete();
  }

  changePageSubTable(eventSub): void {
    this._dynamicTableSubTable.requestParams.page = eventSub;
    this.loadTableSubTable();
  }

  changeManyItensSubTable(eventSub): void {
    this._dynamicTableSubTable.requestParams.limit = eventSub;
    this._dynamicTableSubTable.requestParams.page = 1;
    this.loadTableSubTable();
  }

  trackArray(indexSub): number {
    return indexSub;
  }

  async loadTableSubTable(reloadFilter = false) {
    if (reloadFilter) {
      this.dataSourceSubTable.loadFilters(this._dynamicTableSubTable.endpoint, this._dynamicTableSubTable.requestParams, this.attributeParams);
    }
    this.dataSourceSubTable.loadRequestSubTable(this._dynamicTableSubTable.endpoint, this._dynamicTableSubTable.requestParams, this.attributeParams, this.filterParams, false, this.indexSubTable);
  }

  async loadTableSub(requestGet = false) {
    this.dataSourceSubTable.loadFilters(this._dynamicTableSubTable.endpoint, this._dynamicTableSubTable.requestParams, this.attributeParams);
    this.dataSourceSubTable.loadRequestSubTable(this._dynamicTableSubTable.endpoint, this._dynamicTableSubTable.requestParams, this.attributeParams, null, requestGet, this.indexSubTable);
  }

  async reloadStickySubTable() {
    await this.sleepSub(1);
    this.table.updateStickyColumnStyles();
  }

  sortFunctionDataSubTable(a, b) {
    let lenghtSortSub = 0;
    const typeData = typeof a[this._dynamicTableSubTable.requestParams.sort];

    if (typeData === 'undefined') {
      lenghtSortSub = (a[this._dynamicTableSubTable.requestParams.sort] === undefined && b[this._dynamicTableSubTable.requestParams.sort] !== undefined) ? 1 : -1;
    } else if (typeData === 'object') {
      const strA = a[this._dynamicTableSubTable.requestParams.sort].toString().toLowerCase();
      const strB = b[this._dynamicTableSubTable.requestParams.sort].toString().toLowerCase();
      lenghtSortSub = this.validObjectTypeSortSubTable(strA, strB);
    } else if (this.validAllDateSubTable(a[this._dynamicTableSubTable.requestParams.sort], b[this._dynamicTableSubTable.requestParams.sort])) {
      const dates = this.attributeDateSortSubTable(a[this._dynamicTableSubTable.requestParams.sort], b[this._dynamicTableSubTable.requestParams.sort]);
      lenghtSortSub = this.validLenghtSortSubTable(dates.firstDate, dates.secondDate);
    } else if (!isNaN(parseFloat(a[this._dynamicTableSubTable.requestParams.sort]))) {
      const auxA = parseFloat(a[this._dynamicTableSubTable.requestParams.sort]);
      const auxB = parseFloat(b[this._dynamicTableSubTable.requestParams.sort]);
      lenghtSortSub = this.validLenghtSortSubTable(auxA, auxB);
    } else if (typeData === 'number') {
      lenghtSortSub = this.validLenghtSortSubTable(a[this._dynamicTableSubTable.requestParams.sort], b[this._dynamicTableSubTable.requestParams.sort]);
    } else if (typeData === 'string') {
      const strA = a[this._dynamicTableSubTable.requestParams.sort].toString().toLowerCase();
      const strB = b[this._dynamicTableSubTable.requestParams.sort].toString().toLowerCase();

      lenghtSortSub = this.validLenghtSortSubTable(strA, strB);
    }

    return lenghtSortSub;
  }

  validLenghtSortSubTable(subA, subB) {
    return (subA > subB) ? 1 : -1;
  }

  validObjectTypeSortSubTable(objectSubA, objectSubB) {
    if (objectSubA === '' && objectSubB !== '') {
      return 1;
    } else if (objectSubA !== '' && objectSubB === '') {
      return -1;
    }

    return this.validLenghtSortSubTable(objectSubA, objectSubB);
  }

  validDateSortSubTable(dateSub, formatSub) {
    return moment(dateSub, formatSub, true).isValid();
  }

  validAllDateSubTable(dateA, dateB) {
    return (this.validDateSortSubTable(dateA, this.dateFormat) && this.validDateSortSubTable(dateB, this.dateFormat)) ||
      (this.validDateSortSubTable(dateA, this.dateHourFormat) && this.validDateSortSubTable(dateB, this.dateHourFormat));
  }

  attributeDateSortSubTable(firstDateSub, secondDateSub) {
    let dateSubA;
    let dateSubB;
    if (this.validDateSortSubTable(firstDateSub, this.dateFormat)) {
      dateSubA = moment(firstDateSub, this.dateFormat, true);
      dateSubB = moment(secondDateSub, this.dateFormat, true);
    } else {
      dateSubA = moment(firstDateSub, this.dateHourFormat, true);
      dateSubB = moment(secondDateSub, this.dateHourFormat, true);
    }

    return { 'firstDate': dateSubA, 'secondDate': dateSubB };
  }


  handleSortSubTable(attSub: string): void {
    if (this._dynamicTableSubTable.requestParams.sort === attSub) {
      if (this._dynamicTableSubTable.requestParams.order === 'desc') {
        this._dynamicTableSubTable.requestParams.order = 'asc';
        this._dynamicTableSubTable.requestParams.sort = attSub;
      } else if (this._dynamicTableSubTable.requestParams.order === 'asc') {
        this._dynamicTableSubTable.requestParams.order = 'desc';
        this._dynamicTableSubTable.requestParams.sort = attSub;
      }
    } else {
      this._dynamicTableSubTable.requestParams.order = 'asc';
      this._dynamicTableSubTable.requestParams.sort = attSub;
    }
    this.data.sort((a, b) => this.sortFunctionDataSubTable(a, b));

    if (this._dynamicTableSubTable.requestParams.order === 'desc') {
      this.data.reverse();
    }

    this.dataSourceSubTable.loadRequestOrderSubTable(this.data)
  }

  emptyTextSubTable(eSub: any) {
    return eSub ? eSub : '-';
  }

  setFilterOptionsSubTable(optionsSub: any) {
    this._dynamicTableSubTable.displayedColumns.forEach((column) => {
      this.filterOptions[column.attribute] = [];
    });
    if (optionsSub) {
      Object.keys(optionsSub).forEach((key) => {
        if (optionsSub[key]) {
          const auxSub = this._dynamicTableSubTable.displayedColumns.find(
            (item) => item.attribute === key
          );
          if (auxSub) {
            const tipoSub = auxSub.type;
            optionsSub[key].forEach((element) => {
              this.updateFilterElementsSubTable(key, element, tipoSub);
            });
          }
        }
      });
    }
  }

  updateFilterElementsSubTable(keySub, elementSub, tipoSub) {
    if (elementSub === null) {
      this.filterElementSubTable(keySub, elementSub, ' ');
    } else if (tipoSub === 'number') {
      this.filterElementSubTable(keySub, elementSub, this.decimalPipe.transform(elementSub, '1.0-2'));
    } else if (tipoSub === 'text' || tipoSub === 'array') {
      this.filterElementSubTable(keySub, elementSub, elementSub);
    } else if (tipoSub === 'date') {
      this.filterElementSubTable(keySub, elementSub, this.datePipe.transform(elementSub, 'dd/MM/yyyy'));
    } else if (tipoSub === 'date-time') {
      this.filterElementSubTable(keySub, elementSub, this.datePipe.transform(elementSub, "dd/MM/yyyy 'às' HH:mm"));
    } else if (tipoSub === 'bool') {
      this.filterElementSubTable(keySub, elementSub, this.elementBoolValidSubTable(elementSub));
    }
  }

  elementBoolValidSubTable(elementSub) {
    return elementSub ? 'Sim' : 'Não';
  }

  filterElementSubTable(keySub, valueElementSub, friendlyElementSub) {
    this.filterOptions[keySub].push({
      value: valueElementSub,
      friendly: friendlyElementSub,
      check: false
    });
    this.setFilterSub(this.filterOptions)
  }

  handleFilter(filters: any, att: string, type?) {
    const index = this.filterParams.findIndex((item) => item.param === att);
    if (index > -1) {
      this.filterParams.splice(index, 1);
    }

    if (filters.filter.length > 0) {
      if (['activities', 'routes', 'carbonContent'].includes(att)) {
        this.filterParams.push({
          param: att,
          value: filters.filter
            .map((item) => item.value.replace(/,/g, '.'))
            .join(','),
        });
      } else {

        this.filterParams.push({
          param: att,
          value: filters.filter.map((item) => item.value).join(','),
        });
      }
    }else{
      const indexRemove = this.attributeParams.findIndex((item) => item.param === att);
      if(indexRemove > -1){
        this.attributeParams.splice(indexRemove,1)
      }
    }

    this.flagEditAll = this.filterParams.length > 0 ? true : false;
    this.filterActive = this.filterParams.length > 0 ? true : false;
    this.filter.emit({ filterParams: this.filterParams, att: att });
    this._dynamicTableSubTable.requestParams.page = 1;
    this.loadTableSub(this.filterActive);
  }

  handlePin(column: any) {
    if (!column.sticky) {
      column['sticky'] = true;
    } else {
      column.sticky = !column.sticky;
    }
  }

  handleEdit(event: any): void {
    this.editRow = new Array(this.data.length).fill(false);
    this.getEdit.emit(this.editRow);
    this.edit.emit(event);
  }

  handleDuplicate(event: any): void {
    const subTableDuplicate = {
      indexTable: this.elementId,
      subTableObj: event
    }
    this.duplicate.emit(subTableDuplicate);
  }

  handleDelete(event: any): void {
    const subTableDeleteLine = {
      indexTable: this.elementId,
      subTableObj: event
    }
    this.delete.emit(subTableDeleteLine);
  }

  handleView(event: any): void {
    const subTableViewLine = {
      indexTable: this.elementId,
      subTableObj: event
    }
    this.view.emit(subTableViewLine);
  }

  modalEditElement(event: any): void {
    const subTableEditModalLine = {
      indexTable: this.elementId,
      subTableObj: event
    }
    this.modalEdit.emit(subTableEditModalLine);
  }

  setEdit(indexRow, element) {
    this.editRow[indexRow] = true;
    const subTableSetEdit = {
      indexTableLine: this.elementId,
      subTableLine: this.editRow,
      value: element
    }
    this.getEdit.emit(subTableSetEdit);
  }

  setRowColSubTable(rowSub, colSub) {
    let inputSub = document.getElementById(`[${rowSub + 1}][${colSub}]`);
    if (!inputSub) {
      inputSub = document.getElementById(`[${0}][${colSub}]`);
    }
    inputSub.focus();
  }

  decimalFilterSubTable(eventSub: any) {
    const regSub = /^\d*(?:[.,]\d{0,2})?$/;
    const inputSub = eventSub.target.value + String.fromCharCode(eventSub.charCode);
    if (!regSub.test(inputSub)) {
      eventSub.preventDefault();
    }
  }

  sleepSub(mls: number) {
    return new Promise((resolve) => setTimeout(resolve, mls));
  }

  changeDateSubTable(eventSub: any) {
    this.flagFilter = eventSub ? false : true;
  }

  getCheckboxAll(checkbox) {
    this.ids = this.data.map(data => data.id)

    if (checkbox._checked) {
      this.data.forEach(res => {
        res.checked = checkbox._checked;
        if (checkbox._checked) {
          this.checked.push(res.id);
        }
      })
      this.ids2.splice(0);
      this.ords.push(this.ids);
      this.ids2.push(this.ids);
      const objIds = {
        ids: this.ids,
        checked: checkbox._checked,
        idLineTable: this.elementId
      };
      this.idsOrdersSubTable.emit(objIds);
      this.nav.changeReturnIds.emit(objIds);
    } else {
      this.data.forEach(res => {
        res.checked = checkbox._checked;
      })
      this.ords.splice(0);
      this.ids2.splice(0);
      this.idsOrdersSubTable.emit(this.ords.toString());
      const objIds = {
        ids: this.ids,
        checked: checkbox._checked,
        idLineTable: this.elementId
      };
      this.nav.changeReturnIds.emit(objIds);
    }

  }

  getCheckboxOne(index) {
    this.checks[index] = !this.checks[index];
    if (this.checks[index]) {
      this.checked.push(this.data[index].id);
      this.ords.push(this.data[index].id);
      const objSubIds = {
        ids: this.ords,
        idLineTable: this.elementId
      };
      this.idsOrdersSubTable.emit(objSubIds);
    } else {
      this.checked.splice(this.checked.indexOf(this.data[index].id), 1);
      this.ords.splice(this.ords.indexOf(this.data[index].id), 1);
      const objSubIds = {
        ids: this.ords,
        idLineTable: this.elementId
      };
      this.idsOrdersSubTable.emit(objSubIds);
    }
  }

  clearAllFilters(reset = true): void {
    this.filterTable?.forEach((child) => {
      child.clearFilter(false);
    });
    this.filterParams = [];
    this.flagEditAll = false;
    this.filter.emit(this.filterParams);
    this.closedSubTable.emit(true)
    this._dynamicTableSubTable.requestParams.page = 1;
    this.loadTableSubTable(reset);
  }

  uncheckAll() {
    this.checked = [];
    this.ords = [];
    this.checks = [];
  }
}
