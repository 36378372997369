import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import { TooltipInduxtryComponent } from './tooltip-induxtry.component';
import { TooltipDirective } from '../../../directives/tooltip/tooltip-directives';


@NgModule({
  declarations: [
    TooltipInduxtryComponent,
    TooltipDirective
  ],
  imports: [
    CommonModule
  ],
  exports: [TooltipDirective]
})
export class TooltipModule {
}