import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { SuffixInput, Warning } from '../../../../../utils/models/input.interface';
import { returnColorWarning, returnTextWarning, returnWarningIcon } from '../../../functions/utilities.service';

@Component({
  selector: 'suffix-input',
  templateUrl: './suffix-input.component.html',
  styleUrls: ['./suffix-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: SuffixInputComponent,
    },
  ],
})
export class SuffixInputComponent implements OnInit, ControlValueAccessor {
  warningIconSuffixInput = returnWarningIcon;
  colorWarningSuffixInput = returnColorWarning;
  textWarningSuffixInput = returnTextWarning;

  @Input() suffixInput: SuffixInput;

  @Input() warning: Warning = {
    sucess: null,
    error: null,
    info: null,
    alert: null,
    load: null
  };

  @Output() valueEmitter: EventEmitter<string> = new EventEmitter<string>();
  @Output() keydownTab: EventEmitter<string> = new EventEmitter<string>();

  constructor() { }

  writeValue(value: string): void {
    this.suffixInput.value = value;
  }

  onChange = (value: any) => {
    this.suffixInput.value = value
    return value;
  };

  onTouched = (value: any) => {
    this.suffixInput.value = value
    return value;
  };

  registerOnChange(onChange: (value: any) => any) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }

  ngOnInit(): void {
  }

  setValue() {
    this.onChange(this.suffixInput.value);
    this.valueEmitter.emit(this.suffixInput.value);
  }

  handleKeydownTab(): void {
    this.keydownTab.emit('');
  }
}
