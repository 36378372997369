import { EventEmitter, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class MapBoxService {
  readonly plotFinalizado = new EventEmitter();

  constructor() { }
  
  finishEmit() {
    this.plotFinalizado.emit(true);
  }
}
