import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TagInsertInterface, TagInterface } from '../../../../../utils/models/miscelanea.interface';
import { TextInput } from '../../../../../utils/models/input.interface';
import { FormBuilder, FormControl } from '@angular/forms';

@Component({
  selector: 'insert-tags',
  templateUrl: './insert-tags.component.html',
  styleUrls: ['./insert-tags.component.scss']
})
export class InsertTagsComponent implements OnInit {
  @Input() tagInterface: TagInterface[];
  @Input() inputInterface: TextInput;
  @Input() add: boolean;
  @Input() edit: boolean;
  @Input() tagDefault: TagInsertInterface = {icon: '', class: 'info'};
  @Output() tagsValue: EventEmitter<Array<TagInterface>> = new EventEmitter();

  tagInterfaceInsertion: TagInterface[];

  textInterface: TextInput;

  tagAux: TagInterface;

  form = this.formBuilder.group({
    textValue: new FormControl(''),
  });

  constructor(
    private readonly formBuilder: FormBuilder) { }

  ngOnInit(): void {
    this.form.get('textValue').setValue(this.inputInterface.value);
    this.textInterface = {
      value: this.form.value.textValue,
      label: this.inputInterface.label,
      placeholder: this.inputInterface.placeholder
    }

    if(this.tagInterface){
      this.tagInterfaceInsertion = this.tagInterface;
    } else {
      this.tagInterfaceInsertion = [];
    }
  }

  handleClick() {
    if (
      this.add && this.form.value.textValue !== '' &&
      this.form.value.textValue !== null &&
      this.form.value.textValue !== undefined
    ) {
      this.tagInterfaceInsertion.push(
        {
          key: this.generateRandomId(2),
          icon: this.tagDefault.icon,
          class: this.tagDefault.class,
          text: this.form.value.textValue,
          action: true
        }
      )
      this.form.get('textValue').setValue('');
      this.form.reset();
    }
    this.tagsValue.emit(this.tagInterfaceInsertion)
  }

  generateRandomId(size: number): string {
    let randomString = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    for (let i = 0; i < size; i++) {
      randomString += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return randomString;
  }

  statusTag(action) {
    if (this.add || this.edit){
      this.tagInterfaceInsertion = this.tagInterfaceInsertion.filter(item => item.key !== action);
      this.tagsValue.emit(this.tagInterfaceInsertion);
    }
  }

  handleClickEdition(event: TagInterface): void {
    const indexTag = this.tagInterfaceInsertion.findIndex(item => item.key === event.key);
    if (indexTag !== -1) {
      this.tagInterfaceInsertion[indexTag] = event;
    }
    this.tagsValue.emit(this.tagInterfaceInsertion);
  }
}
