import { Component, Input, OnInit, EventEmitter, Output} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DatapickerInput, Warning } from './../../../../../utils/models/input.interface';
import { returnColorWarning, returnTextWarning, returnWarningIcon } from '../../../../shared/functions/utilities.service';

@Component({
  selector: 'datapicker-input',
  templateUrl: './datapicker-input.component.html',
  styleUrls: ['./datapicker-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: DatapickerInputComponent,
    },
  ],
})
export class DatapickerInputComponent implements OnInit, ControlValueAccessor {
  warningIconDatePicker = returnWarningIcon;
  colorWarningDatePicker = returnColorWarning;
  textWarningDatePicker = returnTextWarning;

  @Input() datapicker: DatapickerInput;

  @Input() warning: Warning = {
    sucess: null,
    error: null,
    info: null,
    alert: null,
    load: null
  };

  @Output() valueEmitter: EventEmitter<any> = new EventEmitter<any>();

  constructor() { }

  writeValue(value: any): void {
    this.datapicker.date = value;
  }

  onChange = (value: any) => {
    return value;
  };

  onTouched = (value: unknown) => {
    return value;
  };

  registerOnChange(onChange: (value: any) => any) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }

  ngOnInit(): void {
  }

  setNewDate() {
    this.onChange(this.datapicker.date);
    this.valueEmitter.emit(this.datapicker.date);
  }
}
