import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TagInterface } from '../../../../../utils/models/miscelanea.interface';

@Component({
  selector: 'tags',
  templateUrl: './tags.component.html',
  styleUrls: ['./tags.component.scss']
})
export class TagsComponent {
  @Input() tagInterface: TagInterface;
  @Input() edit: boolean;
  @Input() labelEdit: string;

  @Output() handleClickEdition: EventEmitter<TagInterface> = new EventEmitter();
  @Output() handleClick: EventEmitter<string> = new EventEmitter();

  isButtonClicked = false;
  colorClicked = '';
  openEditedtag: boolean;

  constructor() { }

  handleClickedOut() {
    this.openEditedtag = false;
  }

  handleClickAction(event): void {
    this.handleClick.emit(this.tagInterface.key);

    this.colorClickedClass(event);
  }

  handleClickTagEdition(): void {
    this.openEditedtag = true;
  }

  handleChangeInputEditTag(event: string): void {
    this.openEditedtag = false;
    this.tagInterface.text = event;
    this.handleClickEdition.emit(this.tagInterface);
  }

  colorClickedClass(value){
    if(value === 'warning') {
      this.colorClicked = 'var(--yellow-500)'
    } else if (value === 'info') {
      this.colorClicked = 'var(--blue-500)'
    } else if (value === 'info-dark') {
      this.colorClicked = 'var(--purple-500)'
    } else if (value === 'success') {
      this.colorClicked = 'var(--green-500)'
    } else if (value === 'error') {
      this.colorClicked = 'var(--red-500)'
    }
    this.isButtonClicked = true;
    setTimeout(() => {
      this.isButtonClicked = false;
    }, 300)
  }
}
