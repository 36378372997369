import { Component, Input, OnInit } from '@angular/core';
import { LinkInterface } from '../../../../../utils/models/common.interface';

@Component({
  selector: 'links-induxtry',
  templateUrl: './links.component.html',
  styleUrls: ['./links.component.scss']
})
export class LinksComponent implements OnInit {
  linkClick: boolean;
  @Input() link: LinkInterface;

  ngOnInit(): void {
    if(this.link.target === '' || this.link.target === null) {
      this.link.target = '_blank';
    }
  }

  onLinkClick(font) {
    if(!this.linkClick){
      const value = font+'_click'
      this.link.font = value
      this.linkClick = true;
    } else {
      const value = this.link.font
      this.link.font = value
    }
  }

}
