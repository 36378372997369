import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AvatarInterface, MultiAvatarInterface } from '../../../../../utils/models/miscelanea.interface';

@Component({
  selector: 'avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss']
})
export class AvatarComponent implements OnInit {

  edit: string;

  @Input() avatar: AvatarInterface;
  @Input() multiAvatar: MultiAvatarInterface;
  @Input() size: string;

  @Output() eventEmitEdit: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() eventEmitAdd: EventEmitter<boolean> = new EventEmitter<boolean>();

  ngOnInit(): void {
    if (this.avatar?.status === 'edit') {
      this.edit = 'Edit'
    } else if (this.avatar?.status === 'locked') {
      this.edit = 'lock'
    }
  }

  get nameAvatar() {
    let name = '';
    this.avatar?.name?.split(' ').forEach((nm, idx) => {
      if (idx <= 1) {
        name += nm.substring(0, 1);
      }
    })
    return name;
  }

  multiNameAvatar(value: string) {
    let name = '';
    value?.split(' ').forEach((nm, idx) => {
      if (idx <= 1) {
        name += nm.substring(0, 1);
      }
    })
    return name;
  }

  handleClick(value: string) {
    if (value === 'edit') {
      this.eventEmitEdit.emit(true)
    } else if (value === 'multAvatar') {
      this.eventEmitAdd.emit(true)
    }
  }

}
