import { HttpService } from './../../service/http/http.service';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { BannerInterface } from '../../../utils/models/common.interface';

@Injectable({
  providedIn: 'root',
})
export class NavigationService {
  private readonly endpointSimulations: string = 'scenarios';
  readonly newSimutaionEvent = new EventEmitter();
  readonly newSimutaionEventGrafic = new EventEmitter();
  readonly newSimutaionEventFilterDate = new EventEmitter();
  readonly newOtimizarEvent = new EventEmitter();
  readonly newSimutaionEventResultsDate = new EventEmitter();
  readonly newConfigEvent = new EventEmitter();
  readonly changeReturnIds = new EventEmitter();
  readonly newEditarEvent = new EventEmitter();
  readonly changeReturnMax = new EventEmitter();
  readonly reoderLoaded = new EventEmitter();
  readonly selectGantt = new EventEmitter();
  readonly banner = new EventEmitter();

  private readonly planningEndpoint: string = environment.apiUrl;

  valueIds = [];

  constructor(
    private readonly httpService: HttpService,
    private readonly http: HttpClient
    ) {}

  emitNewBanner(banner: BannerInterface){
    this.banner.emit(banner)
  }

  emitNewSimulation() {
    this.newSimutaionEvent.emit();
  }

  emitNewOtimizar(status) {
    this.newOtimizarEvent.emit(status);
  }

  emitNewGrafic(status) {
    this.newSimutaionEventGrafic.emit(status);
  }

  emitNewfilterDate(element) {
    this.newSimutaionEventFilterDate.emit(element);
  }

  emitNewResultsDate(element) {
    this.newSimutaionEventResultsDate.emit(element);
  }

  emitNewConfigurate(){
    this.newConfigEvent.emit();
  }

  emitChangeReturnIds(event){
    this.changeReturnIds.emit(event);
  }

  emitValueId(ids: any){
    this.valueIds.push(ids);
  }

  emitNewEditar(status) {
    this.newEditarEvent.emit(status);
  }

  emitValueMax(value: any) {
    this.changeReturnMax.emit(value);
  }

  emitReoderLoaded(element) {
    this.reoderLoaded.emit(element);
  }

  emitSelectGantt(element) {
    this.selectGantt.emit(element);
  }

  // getIdsParadas(id:any){
  //   const endPoint2 = `newMaintenanceInputs?scenario=${id}`;
  //   return this.httpService
  //   .genericGetNew(endPoint2)
  //   .pipe((res) => {
  //   return res;
  //   })
  // }

  getSimulation(id: string) {
    return this.httpService
      .genericGet(this.endpointSimulations, id)
      .pipe((res) => {
        return res;
      });
  }

  editSimulations(id: string, data: any) {
    const aux = `${this.endpointSimulations}/${id}`;

    return this.httpService.genericPut(aux, data).pipe((res) => {
      return res;
    });
  }

  getNotifications(){
    return this.http.get(`${this.planningEndpoint}/systemUpdates`);
  }

}
