import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'check-list',
  templateUrl: './check-list.component.html',
  styleUrls: ['./check-list.component.scss']
})
export class CheckListComponent implements OnInit {
  @Input() listItens;
  @Input() minHeigth;
  @Input() maxHeigth;

  @Output() checkedItens: EventEmitter<any> = new EventEmitter<any>();

  selectedItems: string[] = [];
  searchTerm = '';
  filteredOptions: any[] = [];
  checkAll = false;

  constructor() { }

  ngOnInit() {
    if (this.listItens === undefined) {
      this.listItens = [{ name: 'Option' }, { name: 'Item' }, { name: 'Outro Item' }, { name: 'Another Item' }]
    }

    this.listItens?.forEach((itens: any) => {
      itens.checked = false;
    })

    this.filterOptions();
  }

  isSelected(option: string) {
    return this.selectedItems.includes(option);
  }

  checkSelection(option: string, event) {
    this.verifyCheckAll(event.checked)
    if (this.isSelected(option)) {
      this.selectedItems = this.selectedItems.filter(item => item !== option);
    } else {
      this.selectedItems.push(option);
    }
    this.checkedItens.emit(this.filteredOptions)
  }

  verifyCheckAll(event) {
    if (event) {
      const allChecked = this.filteredOptions.every(objeto => objeto.checked === true);
      if (allChecked) {
        this.checkAll = true;
      }
    } else {
      this.checkAll = false;
    }
  }

  filterOptions() {
    this.filteredOptions = this.listItens.filter(option =>
      option.name.toLowerCase().includes(this.searchTerm.toLowerCase())
    );
    const allChecked = this.filteredOptions.every(objeto => objeto.checked === true);
    this.checkAll = (allChecked === true ? true : false);
  }

  checkAllItens(e) {
    if (e.checked) {
      this.filteredOptions.forEach((element: any) => {
        element.checked = true;
        this.selectedItems.push(element)
      });
      this.checkedItens.emit(this.filteredOptions)
    } else {
      this.filteredOptions.forEach((element: any) => {
        element.checked = false;
      });
      this.checkedItens.emit(this.filteredOptions)
    }
  }

}
