import { Component, Input, OnInit } from '@angular/core';
import { MenuLink, MenuLinkMobile, MenuMobile } from '../../../../../utils/models/layout.interface';
import { TranslateLanguageService } from '../../common/translate-language/service/translate-language.service';

@Component({
  selector: 'menu-mobile',
  templateUrl: './menu-mobile.component.html',
  styleUrls: ['./menu-mobile.component.scss']
})
export class MenuMobileComponent implements OnInit {
  @Input() menus: MenuMobile;

  primaryRouters: MenuLinkMobile[];
  secondaryRouters: MenuLinkMobile[] | [];
  nowLanguage = this.translateLanguageService.returnNowLanguage();
  open: boolean;

  constructor(
    private readonly translateLanguageService: TranslateLanguageService
  ) {}


  ngOnInit(): void {
    this.formatRoutersMenu();
    this.translateLanguageService.changeTranslation.subscribe((language: string) => {
      this.nowLanguage = language;
    });
  }

  formatRoutersMenu() {
    this.primaryRouters = [ ...this.menus.primary ];
    this.secondaryRouters = (this.menus.secondary ? [ ...this.menus.secondary ] : []);
    if (this.primaryRouters.length >= 5 && !(this.primaryRouters.length === 5 && this.secondaryRouters.length === 0)) {
      this.secondaryRouters = (this.menus.secondary ? [ ...this.menus.secondary ] : []);
      const beforePrimaryArray = [ ...this.primaryRouters ];
      for (let index = (this.primaryRouters.length - 1); index >= 4; index--) {
        this.secondaryRouters.push(beforePrimaryArray[index]);
        this.primaryRouters.splice(index, 1);
      }
    }
  }

  toggleOpenMenu() {
    this.open = !this.open;
  }

  closeMenu(event) {
    if (event.currentTarget === event.target) {
      this.toggleOpenMenu();
    }
  }
}
