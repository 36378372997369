import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { CalendarPickerConfig } from 'src/utils/models/calendar.interface';
import { TranslateLanguageService } from '../common/translate-language/service/translate-language.service';
import moment from 'moment';
import { FilterPeriodService } from '../../service/filter-period.service';
import { modalLoading } from '../../functions/utilities.service';
import { MatDialog } from '@angular/material/dialog';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'filter-period',
  templateUrl: './filter-period.component.html',
  styleUrls: ['./filter-period.component.scss']
})
export class FilterPeriodComponent implements OnInit {
  subscribeDestroyed: Subject<any> = new Subject();
  openFilter: boolean;
  getPeriod = JSON.parse(this.filterPeriodService.getPeriod()) || null;
  initalValue = this.getPeriod && this.getPeriod.startDate !== null && this.getPeriod.endDate !== null ? this.getPeriod : null;
  resetPeriod = 0;
  periodCalendar: CalendarPickerConfig = { mode: 'PERIODO', label: 'Período', max: new Date('2023-12-31 00:00:00.000'), min: new Date('2023-01-01 00:00:00'), initialValue: this.initalValue};

  formFilterPeriod = this.formBuilder.group({
    period: new FormControl(null, Validators.required)
  });

  loadingFilter = {
    title: '',
    description: ''
  }
  errorFilter = {
    title: '',
    description: ''
  }
  successFilter = {
    title: '',
    description: ''
  }

  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly translateLanguageService: TranslateLanguageService,
    private readonly filterPeriodService: FilterPeriodService,
    private readonly dialog: MatDialog,
    private readonly toastr: ToastrService
  ) { }

  ngOnInit(): void {
    this.translateNames();
    this.fillInitialPeriod();
    this.translateLanguageService.changeTranslation.subscribe((language: string) => {
      this.translateNames();
    });
  }

  fillInitialPeriod() {
    if (!this.getPeriod) {
      this.initalValue = {
        "startDate": "2023-01-01T03:00:00.000Z",
        "endDate": "2023-12-31T03:00:00.000Z"
      }
      this.formFilterPeriod.get('period').setValue(this.initalValue);
      this.changeValueFilter();
    } else {
      this.formFilterPeriod.get('period').setValue(this.initalValue)
    }
  }

  translateNames(): void {
    this.translateLanguageService.returnTranslate('filtroPeriodo').subscribe(res => {
      this.periodCalendar = { mode: 'PERIODO', label: res.periodo, max: new Date('2023-12-31 00:00:00.000'), min: new Date('2023-01-01 00:00:00'), initialValue: this.initalValue};
      this.loadingFilter = {
        title: res.loading.titulo,
        description: res.loading.descricao,
      }
      this.errorFilter = {
        title: res.erroFiltrar.titulo,
        description: res.erroFiltrar.descricao
      }
      this.successFilter = {
        title: res.sucessoFiltrar.titulo,
        description: res.sucessoFiltrar.descricao
      }
    });
  }

  handleToggleOpenFilter(): void {
    this.openFilter = !this.openFilter;
  }

  handleClearFilter(): void {
    this.initalValue = null;
    this.formFilterPeriod.reset();
    this.resetPeriod++;
    this.filterPeriodService.clearPeriod();
  }

  changeValueFilter() {
    const dialog = modalLoading(this.dialog, this.loadingFilter.title, this.loadingFilter.description);
    this.filterPeriodService.postPeriod(
      this.formFilterPeriod.value.period
    ).pipe(takeUntil(this.subscribeDestroyed)).subscribe(
      (res: any) => {
        this.initalValue = this.formFilterPeriod.value.period;
        this.filterPeriodService.setPeriod(JSON.stringify(this.formFilterPeriod.value.period));
        this.toastr.show(this.successFilter.description, this.successFilter.title, {
          timeOut: 5000,
          messageClass: 'success',
          titleClass: JSON.stringify({})
        });
        dialog.close();
      }, error => {
        this.toastr.show(this.errorFilter.description, this.errorFilter.title, {
          timeOut: 5000,
          messageClass: 'error',
          titleClass: JSON.stringify({})
        });
        dialog.close();
      });
  }

  handleClickFilter(): void {
    this.changeValueFilter();
    this.handleToggleOpenFilter();
  }

  verifyDateIsEqualInitalValue(clear: boolean) {
    if (clear) {
      return false;
    } else {
      return !!(this.formFilterPeriod.value.period === this.initalValue);
    }
  }

  verifyDisabled(clear: boolean): boolean {
    if (
      this.formFilterPeriod.invalid
    ) {
      return true;
    } else if (
      moment(this.formFilterPeriod.value.period?.startDate).diff(moment(this.formFilterPeriod.value.period?.endDate)) === 0 ||
      this.verifyDateIsEqualInitalValue(clear)
    ) {
      return true;
    } else {
      return false;
    }
  }

  get verifyDisabledButtonClear(): boolean {
    return this.verifyDisabled(true);
  }

  get verifyDisabledButtonFilter(): boolean {
    return this.verifyDisabled(false);
  }
}
