import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-modal-reorder',
  templateUrl: './modal-reorder.component.html',
  styleUrls: ['./modal-reorder.component.scss']
})
export class ModalReorderComponent {

  constructor(public dialogRef: MatDialogRef<ModalReorderComponent>, @Inject(MAT_DIALOG_DATA) public data: any) { }

  handleClick(type: boolean): void {
    this.dialogRef.close(type);
  }

}
