import { ModalLoadingComponent } from '../../loads/modal-loading/modal-loading.component';
import { mensagem } from '../../../../../utils/mensagens/mensagens';
import { RequestAttribute, RequestParams } from '../../../../../utils/models/http.interface';
import { CollectionViewer, DataSource } from '@angular/cdk/collections';
import { MatDialog } from '@angular/material/dialog';
import { Observable, BehaviorSubject, of } from 'rxjs';
import moment from 'moment';
import { catchError, finalize } from 'rxjs/operators';

/**
 * Data source for the DynamicTable view. This class should
 * encapsulate all logic for fetching and manipulating the displayed data
 * (including sorting, pagination, and filtering).
 */
export class DynamicSubTableDataSource extends DataSource<any> {
  private readonly lessonsSubjectSubTable = new BehaviorSubject<any[]>([]);
  private readonly loadingSubjectSubTable = new BehaviorSubject<boolean>(false);
  private readonly tableDefault = new BehaviorSubject<boolean>(false);
  private readonly countSubject = new BehaviorSubject<number>(0);
  private readonly filterSubject = new BehaviorSubject<any>({});

  public loading$ = this.loadingSubjectSubTable.asObservable();
  public tableDefault$ = this.tableDefault.asObservable();
  public count$ = this.countSubject.asObservable();
  public data$ = this.lessonsSubjectSubTable.asObservable();
  public filters$ = this.filterSubject.asObservable();

  dialogRef;
  loading = true;
  mensagens = mensagem;
  editDefault;

  constructor(private readonly httpService, public dialog: MatDialog, public externalData?) {
    super();
  }

  /**
   * Connect this data source to the table. The table will only update when
   * the returned stream emits new items.
   * @returns A stream of the items to be rendered.
   */
  connect(collectionViewer: CollectionViewer): Observable<readonly any[]> {
    return this.lessonsSubjectSubTable.asObservable();
  }

  /**
   *  Called when the table is being destroyed. Use this function, to clean up
   * any open connections or free any held resources that were set up during connect.
   */
  disconnect() {
    this.lessonsSubjectSubTable.complete();
    this.loadingSubjectSubTable.complete();
  }

  loadRequestOrderSubTable(data) {
    Promise.resolve(true).then(() => {
      return this.lessonsSubjectSubTable.next(data);
    });
  }

  loadRequestSubTable(
    endpoint: string,
    requestParams: RequestParams,
    fixedAttribute?: RequestAttribute[],
    filtersAttribute?: RequestAttribute[],
    reloadRequest = false,
    indexSubTable?
  ) {
    this.loadingSubjectSubTable.next(true);
    this.loading = true;
    if (this.externalData && !reloadRequest) {
      this.countSubject.next(this.externalData.count);
      this.lessonsSubjectSubTable.next(this.externalData.rows);
      return setTimeout(() => {
        this.dialogRef.close()
      }, 2000);
    } else {

      this.httpService
        .genericGetListTable(
          endpoint,
          requestParams,
          fixedAttribute,
          filtersAttribute
        )
        .pipe(
          catchError(() => of([])),
          finalize(() => this.loadingSubjectSubTable.next(false))
        )
        .subscribe((result: any) => {
          this.loading = false;
          this.dialogRef.close();
          if (result['rows'].length > 0) {
            const data = result['rows'][indexSubTable]['subTable']
            this.countSubject.next(data['count']);
            this.tableDefault.next(false)
            return this.lessonsSubjectSubTable.next(data['rows']);
          } else {
            this.tableDefault.next(true)
            this.countSubject.next(0);
            return this.lessonsSubjectSubTable.next([]);
          }
        });
    }
  }

  formatDate = (date: string): string => {
    return moment(new Date(date)).format('DD/MM');
  };

  loadFilters(
    endpoint: string,
    requestParams: RequestParams,
    attribute?: RequestAttribute[]
  ) {
    const end = `${endpoint}/uniqueAttributes`;
    this.httpService
      .genericGetListTable(end, requestParams, attribute)
      .subscribe((lessons: any) => {
        return this.filterSubject.next(lessons.length !== undefined ? lessons[0] : lessons);
      });
  }

  returnLoading() {
    return this.loading;
  }

  setData(data, endpoint) {
    if (this.dialog.openDialogs.length === 0) {
      this.dialogRef = this.dialog.open(ModalLoadingComponent, {
        autoFocus: false,
        disableClose: true,
        width: '25rem',
        data: {
          title: 'Carregando',
          text: this.mensagens.MENSAGEM_MODAL_LOADING,
        },
      });
    }
    return this.setFilteredData(data, endpoint)
  }

  setFilteredData(data: any, endpoint) {
    this.externalData = data
    this.countSubject.next(data.count);
    this.lessonsSubjectSubTable.next(data.rows);
    if (endpoint === 'scenarios/forYear') {
      return null;
    } else {
      setTimeout(() => {
        this.dialog.closeAll();
      }, 1500);
    }
  }
}
