import { TooltipPosition, TooltipTheme } from './utils/tooltip.enum';
import { AfterViewInit, Component } from '@angular/core';


@Component({
  selector: 'tooltip-induxtry',
  templateUrl: './tooltip-induxtry.component.html',
  styleUrls: ['./tooltip-induxtry.component.scss']
})
export class TooltipInduxtryComponent implements  AfterViewInit {

  position: TooltipPosition = TooltipPosition.DEFAULT;
  theme: TooltipTheme = TooltipTheme.DEFAULT;
  tooltip = '';
  color = '';
  left = 0;
  top = 0;
  visible = false;
  idTooltip = 'divTooltip'


  ngAfterViewInit(): void {
    if (this.tooltip !== '') {
      const divTooltip = document.getElementById(this.idTooltip);
      divTooltip.innerHTML = this.tooltip
      if (this.theme === 'custom') {
        divTooltip.style.background = this.color
      }
      this.postionTooltip(divTooltip)
    }
  }

  postionTooltip(divTooltip) {


    let divRect = divTooltip.getBoundingClientRect();

    if (divRect.left < 0) {
      while (divRect.left < 0) {
        divTooltip.style.left = `${(parseInt(divTooltip.style.left) + 20)}px`;
        divRect = divTooltip.getBoundingClientRect();  // Atualize o valor de divRect
      }
    }

    if (divRect.top < 0) {
      while (divRect.top < 0) {
        divTooltip.style.top = `${(parseInt(divTooltip.style.top) + 20)}px`;
        divRect = divTooltip.getBoundingClientRect();
        if(divRect.bottom >= innerHeight){
          let newHeight = parseInt(divTooltip.style.height) || divTooltip.offsetHeight;
          newHeight -= 100;
          divTooltip.style.height = newHeight + 'px';
        }
      }
    }

    if (divRect.right >= window.innerWidth) {
      while (divRect.right >= window.innerWidth) {
        divTooltip.style.left = `${(parseInt(divTooltip.style.left) - 20)}px`;
        divRect = divTooltip.getBoundingClientRect();
      }
    }

    if (divRect.bottom >= window.innerHeight) {
      while (divRect.bottom >= window.innerHeight) {
        divTooltip.style.height = `${(parseInt(divTooltip.style.top) - 20)}px`;
        divRect = divTooltip.getBoundingClientRect();
      }
    }
  }
}
