import { Warning } from "../../../utils/models/input.interface";
import mockLabels from "../../../utils/mocks/components/graphics/translate-obj-graphics.json";
import html2canvas from "html2canvas";
import { ModalLoadingComponent } from "../components/loads/modal-loading/modal-loading.component";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { PlotlyService } from "../components/graphics/graphic-plotly/services/plotly-service";
import { RequestAttribute } from "src/utils/models/http.interface";
import moment from "moment";
import { ToastrService } from "ngx-toastr";
import { TextToast } from "src/utils/models/common.interface";
import mergeImages from 'merge-base64';
import domtoimage from 'dom-to-image-more';

/** * FU - 001 - Retorna a propriedade do status *
 *
 * @param {Warning} warning - obj com a lista de status.
 * @returns {string} propriedade do status *
 * @throws {  } null.
 *
 * @example
 * const warning: Warning = {
 *  sucess: { active: true, text: 'success' },
 *  error: null,
 *  info: null,
 *  alert: null,
 *  load: null
 * }
 * const result = propertyObjWarning(warning)
 * result é igual a 'sucess'
 **/
export function propertyObjWarning(warning: Warning): string {
  let propertyWarning = 'default';
  if(warning.sucess){
    propertyWarning = 'sucess';
  } else if(warning.error){
    propertyWarning = 'error';
  } else if(warning.info){
    propertyWarning = 'info';
  } else if(warning.alert){
    propertyWarning = 'alert';
  } else if(warning.load){
    propertyWarning = 'load';
  }
  return propertyWarning;
}

/** * FU - 002 - Retorna o item pelo status especifico *
 *
 * @param {Warning} warning - obj com a lista de status.
 * @returns {string} Retorna o icone em que o status está inserido *
 * @throws {  } null.
 *
 * @example
 * const warning: Warning = {
 *  sucess: { active: true, text: 'success' },
 *  error: null,
 *  info: null,
 *  alert: null,
 *  load: null
 * }
 * const result = returnWarningIcon(warning)
 * result é igual a 'warning-sucess'
 **/
export function returnWarningIcon(warning: Warning): string {
  const porpertyIcon = propertyObjWarning(warning);
  const iconsWarning = {
    sucess: 'warning-sucess',
    error: 'warning-error',
    info: 'warning-circle-information',
    alert: 'warning-circle-alert',
    load: 'loading',
  };
  return iconsWarning[porpertyIcon] ? iconsWarning[porpertyIcon] : '';
}

/** * FU - 003 - Retorna a cor do texto da mensagem de erro *
 *
 * @param {Warning} warning - obj com a lista de status.
 * @returns {string} Retorna a cor em que o status está inserido *
 * @throws {  } null.
 *
 * @example
 * const warning: Warning = {
 *  sucess: { active: true, text: 'success' },
 *  error: null,
 *  info: null,
 *  alert: null,
 *  load: null
 * }
 * const result = returnColorWarning(warning)
 * result é igual a { 'color': '#115F22' }
 **/
export function returnColorWarning(warning: Warning): object {
  const porpertyIcon = propertyObjWarning(warning);
  const warningColors = {
    sucess: { 'color': '#115F22' },
    error: { 'color': '#A80B00' },
    info: { 'color': '#1F52AB' },
    alert: { 'color': '#5F4407' },
    load: { 'color': '#102956' },
  };
  return warningColors[porpertyIcon] ? warningColors[porpertyIcon] : {};
}

/** * FU - 004 - Retorna o texto pelo status especifico *
 *
 * @param {Warning} warning - obj com a lista de status.
 * @returns {string} Retorna o texto em que o status está inserido *
 * @throws {  } null.
 *
 * @example
 * const warning: Warning = {
 *  sucess: { active: true, text: 'success' },
 *  error: null,
 *  info: null,
 *  alert: null,
 *  load: null
 * }
 * const result = returnTextWarning(warning)
 * result é igual a 'success'
 **/
export function returnTextWarning(warning: Warning): string {
  const porpertyIcon = propertyObjWarning(warning);
  return warning[porpertyIcon] && warning[porpertyIcon].text ? warning[porpertyIcon].text : 'Warning Message';
}

export function translateLabelsGraphics(language: string, labels: string[]): string[] {
  const objectTranlate = mockLabels[language];
  let labelsArray = labels;
  if (objectTranlate) {
    labelsArray = labels.map(item => objectTranlate[item] ? objectTranlate[item] : item);
  }
  return labelsArray;
}

export function modalLoading(dialog: MatDialog, str?: string, text?: string): MatDialogRef<ModalLoadingComponent> {
  return dialog.open(ModalLoadingComponent, {
    width: '25rem',
    disableClose: true,
    data: {
      title: `${str || ''}`,
      text: `${text || ''}`
    },
  });
}

const plotlyService = new PlotlyService();
export function downloadGraphic(id: string, name: string, toast: ToastrService, textToastr: TextToast, dialog: MatDialog, titleId: string, legendId?: string) {
  const loading = modalLoading(dialog);
  const titleGraph = document.querySelector(`#${titleId}`);
  const scale = 'scale(1)', bodyPage: any = document.body;
  bodyPage.style.webkitTransform =  scale;
  bodyPage.style.msTransform =   scale;
  bodyPage.style.transform = scale;
  domtoimage.toPng(titleGraph).then(async (dataTitleUrl: string) => {
    const plotly = plotlyService.getPlotly();
    plotly.toImage(id, {format: 'png'}).then(async(url) => {
      let urlImage = url;
      const legend: any = document.querySelector(`#${legendId} > div`),
            arrayMerge = [dataTitleUrl.replace('data:image/png;base64,', ''), url.replace('data:image/png;base64,', '')],
            ratioZoom = window.devicePixelRatio || 1;

      if (legendId && legend) {
        const container = legend.querySelector('.container-legend');
        if (container && ratioZoom > 1) {
          container.style.width = `${container.offsetWidth * ratioZoom}px`;
        }
        await domtoimage.toPng(legend).then(async (dataUrl: string) => {
          arrayMerge.push(dataUrl.replace('data:image/png;base64,', ''))
        });
      }
      urlImage = await mergeImages(arrayMerge, {
        direction: true,
        color: '#fff'
      });
      if (legend) {
        const container = legend.querySelector('.container-legend');
        if (container && ratioZoom > 1) {
          container.style.width = 'auto';
        }
      }
      loading.close();
      downloadLink(urlImage, name);
      setTimeout(() => {
        toast.show(
          textToastr.description,
          textToastr.title,
          {
            timeOut: 6000,
            messageClass: 'success',
            titleClass: JSON.stringify({})
          }
        );
      }, 300);
    });
  });
}

export function downloadLink(href: string, name: string): void {
  const downloadLink = document.createElement("a");
  downloadLink.href = href;
  downloadLink.download = name;
  downloadLink.click()
};

export async function getImage(data) {
  try {
    return await html2canvas(data)
      .then((canvas) => {
        const img = canvas.toDataURL('image/png');
        canvas.remove();
        return Promise.resolve(img);
      })
      .catch((error) => {
        return Promise.resolve(null);
      });
  } catch (error) {
    return Promise.resolve(null);
  }
}

/** * FU - 06 - Fazer a inserção, atualização e deletar query params *
 *
 * @param { string } param - nome do query param.
 * @param { string } value - valor do query param.
 * @param { boolean } remove - verificação se vai deletar ou não.
 * @param { RequestAttribute[] } attributes - Array de query params.
 * @returns { RequestAttribute[] } retorna o array de atributos
 * @throws {  } null.
 *
 * @example
 * case 01 - atualizando um query params
 * changeRequestParameter('type', '1512', false, [{param: 'type', value: '123'}]);
 * attribute fica igual a [{param: 'type', value: '1512'}]
 *
 * case 02 - deletando um query params
 * changeRequestParameter('type', '1512', true, [{param: 'type', value: '123'}]);
 * attribute fica igual a []
 *
 * case 03 - inserindo um query params
 * changeRequestParameter('test', '147', false, [{param: 'type', value: '123'}]);
 * attribute fica igual a [{param: 'type', value: '123'}, {param: 'test', value: '147'}]
 **/
export function changeRequestParameter(param: string, value: string, remove: boolean, attributes: RequestAttribute[] = []): RequestAttribute[] {
  const indexParam = attributes.findIndex(res => res.param === param);
  if (indexParam !== -1) {
    if (remove) {
      attributes.splice(indexParam, 1)
    } else {
      attributes[indexParam].value = value;
    }
  } else if (!remove) {
    attributes.push({ param: param, value: value });
  }
  return attributes;
}

export function changePeriodRequestParameter(period, attributes: RequestAttribute[]): RequestAttribute[] {
  attributes = changeRequestParameter(
    'start_date',
    `${moment(period?.startDate).subtract(3, 'hours').unix() * 1000}`,
    (period?.startDate ? false : true), attributes
  );
  attributes = changeRequestParameter(
    'end_date',
    `${moment(period?.endDate).subtract(3, 'hours').unix() * 1000}`,
    (period?.endDate ? false : true), attributes
  );
  return attributes;
}


export function changeValueGraphics(percentage: number, value: number, width: number): string {
  if (width > 1100) {
    return (width <= 1920 ? ((percentage / 100) * width) : (((percentage + 1.2) / 100) * width)).toFixed(2);
  } else {
    return value.toFixed(2);
  }
}

export function changeValueGraphicsPlusSize(percentage: number, value: number, width: number): string {
  return (width > 1920 ? ((percentage / 100) * width) : value).toFixed(2);
}
