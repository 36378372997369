import { Component, EventEmitter, Output, Input, OnInit } from '@angular/core';

// Interface
import { MenuBottom } from '../../../../../utils/models/layout.interface';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

const TIME_SLEEP = 300;
@Component({
  selector: 'app-sidenav-bottom',
  templateUrl: './sidenav-bottom.component.html',
  styleUrls: ['./sidenav-bottom.component.scss']
})
export class SidenavBottomComponent implements OnInit {

  @Input() menuBottom: MenuBottom;
  @Input() collapseControl: any;
  @Input() drawer: any;
  @Output() collapse = new EventEmitter();
  @Output() collapsedUserPanel = new EventEmitter();

  collapseValue;

  hideButton = true
  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset).pipe(map(result => result.matches));

  constructor(private readonly breakpointObserver: BreakpointObserver) { }

  ngOnInit() {
    if (this.collapseControl !== false) {
      this.collapseValue = true;
    }
    this.isHandset$.subscribe((res)=>{
      if(res){
        this.hideButton = false
      } else{
        this.hideButton = true
      }
    })
  }

  sendCollapse() {
    this.drawer.toggle();
    this.sendCollapsedUserPanel();
    this.sleep(TIME_SLEEP).then(() => {
        this.collapseValue = !this.collapseValue;
        this.collapse.emit(this.collapseValue);
    });

    this.sleep(TIME_SLEEP).then(() => {
      this.drawer.toggle();
    });
  }

  sendCollapsedUserPanel() {
    this.collapsedUserPanel.emit(false);
  }

  sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

}
