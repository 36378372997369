import { Component, Input, ViewEncapsulation } from '@angular/core';
import { TooltipInterface } from '../../../../../utils/models/miscelanea.interface';

@Component({
  selector: 'tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TooltipComponent {

  @Input() tooltip: TooltipInterface;

}
