export const mensagem = {
  ALERTA_01: 'Não há itens a serem exibidos.',
  CONFIRMAÇÃO_001: 'Deseja realizar uma nova otimização considerando as alterações feitas?',
  CONFIRMAÇÃO_002: 'Deseja restaurar os valores padrões?',
  CONFIRMAÇÃO_003: 'Tem certeza que deseja excluir esse item?',
  CONFIRMAÇÃO_004: 'Deseja duplicar a otimização otm00x?',
  CONFIRMAÇÃO_005: 'Tem certeza que deseja excluir essa otimização?',
  ERROR_001: 'Para realizar uma inclusão é necessário preencher todos os campos obrigatórios.',
  ERROR_002: 'Preencha todos os campos obrigatórios para que as alterações sejam salvas.',
  ERROR_003: 'Já existe uma indisponibilidade cadastrada para esse equipamento no mesmo horário.',
  ERROR_004: 'Não é possível cadastrar um valor de HMR mínimo maior que o valor do HMR máximo.',
  ERROR_005: 'Usuário e/ou senha incorretos.',
  ERROR_006: 'Insira um email válido',
  ERROR_DEFAULT: 'Algo de errado aconteceu. Tente novamente mais tarde.',
  MENSAGEM_MODAL_LOADING: 'Por favor, aguarde alguns segundos.',
  MENSAGEM_COMPARADOR_SELECT_ERROR: 'Você já selecionou essa otimização na comparação.',
  MENSAGEM_COMPARADOR_GRAFICO: 'Nenhuma otimização selecionada é compatível com o filtro de datas definido.'
}

export function  mensagemErrorCustom(error: any, toastr) {
  if (error.error.message) {
    toastr.show(error.error.message, 'Atenção', { timeOut: 5000, messageClass: 'info' });
  } else {
    toastr.show(mensagem.ERROR_DEFAULT, 'Erro', { timeOut: 5000, messageClass: 'error' });
  }
}
