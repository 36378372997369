import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { Chart, registerables } from 'chart.js';
import 'chartjs-chart-matrix';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import Annotation from 'chartjs-plugin-annotation';
import { ScrollService } from '../../scrolls/service/scroll.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import zoomPlugin from 'chartjs-plugin-zoom';


@Component({
  selector: 'chart',
  templateUrl: './chart.component.html',
  styleUrls: ['./chart.scss']
})

export class ChartComponent implements OnInit, AfterViewInit {
  @ViewChild('graphicChart', { static: true }) readonly graphicChart: any;
  @ViewChild('graphicChart2', { static: true }) readonly graphicChart2: any;
  @ViewChild('graphicChart3', { static: true }) readonly graphicChart3: any;

  @ViewChild('chartContainer') chartContainer: ElementRef;

  @Input() typeGraphic: any = 'line';
  @Input() datasets: any = [];
  @Input() options: any = {};
  @Input() label: any = [];
  @Input() haveScroll = false;
  @Input() simple = true;
  @Input() haveTwoAxis = false;
  @Input() scrollOnTop = false;
  @Input() axisYCTXWidth = 45;
  @Input() axisY1CTXWidth = 45;
  @Input() load = 0;
  @Input() chartDataLabels: boolean;

  chartMod: any;
  private readonly unsubscribeAll: Subject<any[]> = new Subject();

  constructor(
    private readonly scrollService: ScrollService
  ) { }

  ngOnInit() { }

  ngAfterViewInit() {
    if (this.haveScroll) {
      this.chartContainer.nativeElement.addEventListener('scroll', this.onScroll.bind(this));
      this.scrollService.newEvent.pipe(takeUntil(this.unsubscribeAll))
        .subscribe((res) => {
          this.chartContainer.nativeElement.scrollLeft = res
        })
    }
  }

  ngOnChanges() {
    Chart.register(zoomPlugin);
    Chart.register(...registerables);
    if(this.chartDataLabels){
      Chart.register(ChartDataLabels);
    }
    if (
      (this.datasets && this.datasets.length > 0) ||
      (this.load)
    ) {
      this.loadChart();
    }
  }

  loadChart() {
    if (this.chartMod instanceof Chart) {
      this.chartMod.destroy();
    }

    this.chartMod = new Chart(this.graphicChart.nativeElement, {
      type: this.typeGraphic,
      data: {
        labels: this.label,
        datasets: this.datasets,
      },
      options: this.options,
    });

    if (this.haveScroll) {
      new Chart(this.graphicChart2.nativeElement, {
        type: this.typeGraphic,
        data: {
          labels: this.label,
          datasets: this.datasets,
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          layout: {
            padding: {
              bottom: 28,
            }
          },
          scales: {
            x: {
              ticks: {
                display: false
              },
              grid: {
                drawTicks: false,
              }
            },
            y: {
              afterFit: (ctx) => {
                ctx.width = this.axisYCTXWidth;
              }
            },
            y1: {
              display: false,
            },
            xAxes: [{
              ticks: {
                padding: 20
              }
            }]
          },
          plugins: {
            legend: {
              display: false
            }
          }
        }
      });
      if (this.haveTwoAxis) {
        new Chart(this.graphicChart3.nativeElement, {
          type: this.typeGraphic,
          data: {
            labels: this.label,
            datasets: this.datasets,
          },
          options: {
            responsive: true,
            maintainAspectRatio: false,
            layout: {
              padding: {
                bottom: 28,
              }
            },
            scales: {
              x: {
                ticks: {
                  display: false
                },
                grid: {
                  drawTicks: false,
                }
              },
              y1: {
                afterFit: (ctx) => {
                  ctx.width = this.axisY1CTXWidth;
                }
              },
              xAxes: [{
                ticks: {
                  padding: 20
                }
              }]
            },
            plugins: {
              legend: {
                display: false
              }
            }
          }
        });
      }
    }
    if (this.options.plugins.zoom) {
      this.mensageZoom()
    }
  }

  onScroll(event: Event) {
    const scrollPosition = (event.target as HTMLElement).scrollLeft
    this.scrollService.emitScroll(scrollPosition)
  }

  mensageZoom() {
    const chart = document.getElementById('graphicChart')
    const style = chart.style
    const scrollAlert = document.getElementById('scrollAlert');
    chart.addEventListener('wheel', function (event) {
      if (!event.ctrlKey) {
        event.preventDefault();
        scrollAlert.style.display = 'block';
        scrollAlert.style.width = style.width;
        scrollAlert.style.height = style.height;
        scrollAlert.style.opacity = '1';
        setTimeout(() => {
          scrollAlert.style.opacity = '0';
          setTimeout(() => {
            scrollAlert.style.display = 'none';
          }, 500);
        }, 2000);
      }
    });
  }

  ngOnDestroy() {
    this.unsubscribeAll.next();
    this.unsubscribeAll.complete();
  }
}
