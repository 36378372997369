import { Component, ElementRef, Input, OnChanges, OnInit, Output, ViewChild, EventEmitter } from '@angular/core';
import { PlotlyService } from './services/plotly-service';
import { Subscription } from 'rxjs';
import { TranslateLanguageService } from '../../common/translate-language/service/translate-language.service';

@Component({
  selector: 'graphic-plotly',
  templateUrl: './graphic-plotly.component.html',
  styleUrls: ['./graphic-plotly.component.scss']
})
export class GraphicPlotlyComponent implements OnInit, OnChanges {
  @ViewChild('chartGenericPlot', { static: true }) chartGenericPlot: any;

  @Input() layoutGrafic = {};
  @Input() dataGrafic = [];
  @Input() configGrafic = [];
  @Input() idChart = 'chartGenericPlot'

  @Output() getClickPoint: EventEmitter<any> = new EventEmitter();
  @Output() getEventHoverPoint: EventEmitter<any> = new EventEmitter();
  @Output() getEventUnHoverPoint: EventEmitter<any> = new EventEmitter();

  plotlyGrafic
  sub: Subscription;
  subSelect: Subscription;

  constructor(
    private readonly plotService: PlotlyService,
    private readonly translateLanguageService: TranslateLanguageService
  ) { }

  ngOnInit(): void {
    this.sub = this.plotService.exportEvent.subscribe((e) => {
      if (e) {
        this.updateGrafic(e)
      }
    })
    this.subSelect = this.plotService.selectEvent.subscribe((e) => {
      if (e) {
        this.selectEvent(e)
      }
    });

    this.translateLanguageService.returnTranslate('graficos').subscribe(res => {
      const modeBarGroupTranslate = document.querySelectorAll('.modebar-group')
      modeBarGroupTranslate.forEach((item) => {
        const botoesModebarTranslate = item.querySelectorAll('.modebar-btn');
        botoesModebarTranslate[0]?.setAttribute('data-title', res.aumentarZoom);
        botoesModebarTranslate[1]?.setAttribute('data-title', res.diminuirZoom);
        botoesModebarTranslate[2]?.setAttribute('data-title', res.visualizacaoPadrao);
      })
    });
  }

  ngOnChanges() {
    this.initGrafic();
    this.plotlyGrafic = this.plotService.getPlotly();

    this.chartGenericPlot.nativeElement.on('plotly_click', (data: any) => {
      this.getClickPoint.emit(data);
    });

    this.chartGenericPlot.nativeElement.on('plotly_hover', (data: any) => {
      this.getEventHoverPoint.emit(data);
    });

    this.chartGenericPlot.nativeElement.on('plotly_unhover', (data: any) => {
      this.getEventUnHoverPoint.emit(data);
    });


    const modeBarGroup = document.querySelectorAll('.modebar-group')

    modeBarGroup.forEach((item,index) => {
      const botoesModebar = item.querySelectorAll('.modebar-btn');
      botoesModebar[0]?.setAttribute('data-title', 'Aumentar zoom');
      botoesModebar[1]?.setAttribute('data-title', 'Diminuir zoom');
      botoesModebar[2]?.setAttribute('data-title', 'Voltar para visualização padrão');
    });
  }

  initGrafic() {
    if (this.configGrafic) {
      this.plotService.plotGrafic(this.chartGenericPlot.nativeElement, this.dataGrafic, this.layoutGrafic, this.configGrafic);
    } else {
      this.plotService.plotGrafic(this.chartGenericPlot.nativeElement, this.dataGrafic, this.layoutGrafic);
    }
  }

  updateGrafic(e) {
    const newLayout = {
      'mapbox.style': e
    };
    this.plotlyGrafic.update(this.chartGenericPlot.nativeElement, {}, newLayout, this.configGrafic);
  }

  selectEvent(selectedPoints) {
    const updatedData = this.dataGrafic
    updatedData[0].lon = selectedPoints.map(data => data.lon)
    updatedData[0].lat = selectedPoints.map(data => data.lat)
    updatedData[0].z = selectedPoints.map(data => data.value)
    updatedData[0].customdata = selectedPoints.map(data => data.id)

    updatedData[1].lon = selectedPoints.map(data => data.lon)
    updatedData[1].lat = selectedPoints.map(data => data.lat)

    this.plotlyGrafic.update(this.chartGenericPlot.nativeElement, updatedData, this.layoutGrafic, this.configGrafic);
  }

  ngOnDestroy() {
    this.sub?.unsubscribe();
    this.subSelect?.unsubscribe();
  }
}
