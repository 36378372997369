import { Component, Input } from '@angular/core';
import { InformationTab } from '../../../../../../utils/models/layout.interface';

@Component({
  selector: 'tab-induxtry',
  templateUrl: './tab-induxtry.component.html',
  styleUrls: ['./tab-induxtry.component.scss']
})
export class TabInduxtryComponent {
  @Input() title = '';
  @Input() active = false;
  @Input() disabled = false;
  @Input() alert: InformationTab;
}
