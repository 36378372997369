import { Component, Input, OnInit } from '@angular/core';
import { ListInterface } from '../../../../../utils/models/common.interface';

@Component({
  selector: 'lista',
  templateUrl: './lista.component.html',
  styleUrls: ['./lista.component.scss']
})
export class ListaComponent implements OnInit {

  @Input() list: ListInterface;

  ngOnInit(): void {
    
  }
}
