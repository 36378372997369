import { Component, Input, OnInit } from '@angular/core';
import { DataDashboardCard } from 'src/utils/models/common.interface';

@Component({
  selector: 'dashboard-card',
  templateUrl: './dashboard-card.component.html',
  styleUrls: ['./dashboard-card.component.scss']
})
export class DashboardCardComponent implements OnInit {
  @Input() data: DataDashboardCard;

  value: string;
  target: string;
  success: boolean;

  ngOnInit(): void {
    this.value = this.data.value.toFixed(2).replace('.', ',');
    this.target = this.data.target.toFixed(2).replace('.', ',');
    this.success = this.data.value >= this.data.target;
  }
}
