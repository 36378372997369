import { Component, Input, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ModalLoadingComponent } from '../../loads/modal-loading/modal-loading.component';
import mock from './mock.json';
import { createChart } from 'lightweight-charts';
import html2canvas from 'html2canvas';
import moment from 'moment';

@Component({
  selector: 'graphic-tradingView',
  templateUrl: './graphic-tradingView.component.html',
  styleUrls: ['./graphic-tradingView.component.scss']
})
export class GraphicTradingViewComponent implements OnInit {
  private readonly unsubscribeAll: Subject<any[]> = new Subject();

  @Input() idPrint = 'chartGenericTradingView';
  @Input() idChart = 'IDchartGenericTradingView';
  @Input() width = null;
  @Input() height = 600;
  @Input() minHeigth = '4.25rem'
  @Input() doubleY = true;
  @Input() dataGrafic = mock
  @Input() dataSetName = 'datasets';
  @Input() haveBar = false;
  @Input() configApplyOptions = {
    crosshair: {
      // hide the horizontal crosshair line
      horzLine: {
        visible: false,
        labelVisible: false,
      },
      // hide the vertical crosshair label
      vertLine: {
        labelVisible: false,
      },
    },

    // hide the grid lines
    grid: {
      vertLines: {
        visible: true,
      },
      horzLines: {
        visible: true,
      },
    },
  }

  lineSeries1: any;
  lineSeries2: any;
  createdChart: any;
  dataSize: any;

  unitLeft = '';
  unitRigth = '';
  idPopUp = '';

  index = 0;
  theFirst = true;

  constructor(
    private readonly dialog: MatDialog,
  ) { }

  ngOnInit(): void {
    this.plotGraphic()
  }

  formattedDataGrafic(dataGrafic) {
    const data = dataGrafic.map((e, index) => {
      // Formandando data para time e value
      e.newData = e.data.map((itens, index) => {
        const value = itens.value
        itens.value = value;
        itens.info = 'tipo'
        return itens
      })
      if (this.haveBar) {
        e.barData = e.data.map((element) => {
          if (element.barValue !== null && element.barValue !== undefined) {
            return {
              value: element.barValue,
              time: element.time,
            }
          } else {
            e.barData = []
          }
        })
      }

      // Ajustando eixos
      if (e.yAxisID === 'y') {
        e.rightY = true
      } else {
        e.leftY = true
      }

      // Exibir ou remover uma linha
      if (!e.hidden) {
        e.hidden = false
      }

      return e
    })

    this.createGraphicTrandeView(data)
  }

  ngAfterViewInit() {
    this.plotGraphic()
  }

  plotGraphic() {
    if (this.dataGrafic.length > 0) {
      this.formattedDataGrafic(this.dataGrafic)
    }
  }

  createGraphicTrandeView(data) {
    if (this.createdChart !== undefined) {
      this.createdChart.remove()
    }

    if (this.theFirst !== true) {
      this.index = 0;
      const container = document.getElementById('pop' + this.idChart);
      while (container.firstChild) {
        container.removeChild(container.firstChild);
      }
    }

    const elementChart = document.getElementById(this.idChart);
    this.createdChart = createChart(elementChart, {
      width: this.width,
      height: this.height,
      rightPriceScale: {
        visible: this.doubleY,
      },
      leftPriceScale: {
        visible: true,
      },
    });
    this.createdChart.applyOptions(this.configApplyOptions);
    //Line
    if (this.doubleY) {
      this.dataSize = data.length
      data.forEach(serie => {
        if (serie.yAxisID === 'y1') {
          this.unitLeft = serie.unidadeMedida;
        } else {
          this.unitRigth = serie.unidadeMedida;
        }
        if (!serie.hidden) {
          if (serie?.rightY) {
            if (this.haveBar && serie.onlyBars) {
              this.generateBars(serie)
              this.configInfoTagsGrafico(this.barSeries, serie)
            } else {
              this.lineSeries1 = this.createdChart.addLineSeries(
                {
                  color: serie.borderColor,
                  lineStyle: serie.lineStyle ? serie.lineStyle : 0,
                  priceScaleId: 'right',
                  lastValueVisible: false,
                  priceLineVisible: false,
                  priceFormat: {
                    type: 'custom',
                    minMove: 1,
                    formatter: (price) => Math.round(price).toString(),
                  },
                },
              );
              this.lineSeries1.priceScale().applyOptions({
                scaleMargins: {
                  top: 0.3, // leave some space for the legend
                  bottom: 0.25,
                }
              });
              this.configInfoTagsGrafico(this.lineSeries1, serie)
              this.lineSeries1.setData(serie.newData);
            }
          } else if (serie?.leftY) {
            this.lineSeries2 = this.createdChart.addLineSeries(
              {
                color: serie.borderColor,
                priceScaleId: 'left',
                lineStyle: serie.lineStyle ? serie.lineStyle : 0,
                lastValueVisible: false,
                priceLineVisible: false,
                priceFormat: {
                  type: 'custom',
                  minMove: 1,
                  formatter: (price) => Math.round(price).toString(),
                },
              },
            );

            this.lineSeries2.priceScale().applyOptions({
              scaleMargins: {
                top: 0.3, // leave some space for the legend
                bottom: 0.25,
              },
            });
            this.configInfoTagsGrafico(this.lineSeries2, serie)
            this.lineSeries2.setData(serie.newData);
          }
        }
      })
    } else {
      this.dataSize = data.length
      data.forEach(serie => {
        this.unitLeft = serie.unidadeMedida;
        if (!serie.hidden && serie?.rightY) {
          this.lineSeries1 = this.createdChart.addLineSeries(
            {
              color: serie.borderColor,
              priceScaleId: 'left',
              lineStyle: serie.lineStyle ? serie.lineStyle : 0,
              lastValueVisible: false,
              priceLineVisible: false,
              priceFormat: {
                type: 'custom',
                minMove: 1,
                formatter: (price) => Math.round(price).toString(),
              },
            },
          );
          this.configInfoTagsGrafico(this.lineSeries1, serie)
          this.lineSeries1.setData(serie.newData);
        }
      })
    }
    this.createdChart.timeScale().fitContent();
    this.theFirst = false;
  }

  barSeries;
  generateBars(serie) {
    this.barSeries = this.createdChart.addHistogramSeries(
      {
        color: serie.backgroundColor,
        priceScaleId: 'right',
        lastValueVisible: false,
        priceLineVisible: false,
        base: 0,
        priceFormat: {
          type: 'custom',
          minMove: 1,
          formatter: (price) => Math.round(price).toString(),
        },
      }
    );
    this.barSeries.setData(serie.barData)

    this.barSeries.priceScale().applyOptions({
      scaleMargins: {
        top: 0.7,
        bottom: 0,
      }
    });

    const markers = []
    serie.barData.forEach(dataBar => {
      markers.push({ time: dataBar.time, position: 'inBar', color: '#666666', shape: 'text', text: dataBar.value.toString() })
    });
    this.barSeries.setMarkers(markers);
  }

  dateHover: any = ''

  configInfoTagsGrafico(areaSeries, data) {
    const symbolName = data.label;
    const container = document.getElementById('pop' + this.idChart);
    let coluna;
    if (this.index === 0) {
      coluna = document.createElement('div');
      coluna.id = `coluna${this.idChart}${this.index}`;
      coluna.style.display = 'flex';
      coluna.style.flexDirection = 'row';
      coluna.style.flexWrap = 'wrap';
      container.appendChild(coluna);
      this.idPopUp = `coluna${this.idChart}${this.index}`
    }
    const elementsDiv = document.getElementById(this.idPopUp);
    const legend = document.createElement('div');

    legend.style.display = 'flex';
    legend.style.fontSize = '0.75rem';
    legend.style.lineHeight = '0.75rem';
    legend.style.fontWeight = '200';
    elementsDiv.appendChild(legend);

    const formatPrice = price => (Math.round(price * 100) / 100).toFixed(2);

    const setTooltipHtml = (name, date, price, clear) => {
      if (date !== null) {
        this.dateHover = moment(date).format('DD/MM/yyyy') === '31/12/1969' ? '' : moment(date * 1000);
      }
      if (clear) {
        legend.innerHTML = ''
      } else {
        legend.innerHTML = `
          <div style="margin: 0.3125rem 1rem 0.3125rem 0;">
            <div style="color: ${data.backgroundColor}">
              ${name}
            </div>
            <div>
              ${price} ${data.unidadeMedida}
            </div>
          </div>
        `;
      }
    };

    const updateLegend = param => {
      const validCrosshairPoint = !(
        param === undefined || param.time === undefined || param.point.x < 0 || param.point.y < 0
      );
      if (validCrosshairPoint) {

        if (param.seriesData.get(areaSeries) !== undefined) {
          const bar = param.seriesData.get(areaSeries);
          const price = bar.value !== undefined ? bar.value : bar.close;
          const formattedPrice = formatPrice(price);
          setTooltipHtml(symbolName, bar.time, formattedPrice, false);

        } else {
          setTooltipHtml(null, null, null, true);
        }

      } else {
        this.dateHover = ''
        setTooltipHtml(null, null, null, true);
      }
    };

    updateLegend(undefined);
    this.createdChart.subscribeCrosshairMove(updateLegend);
    this.index += 1;
  }

  configTootlip(series, dadosAdicionais) {
    const container = document.getElementById(this.idChart);
    const toolTipWidth = 30;
    // Create and style the tooltip html element
    const toolTip = document.createElement('div');

    //toolTip.style.fontFamily = '-apple-system, BlinkMacSystemFont, Trebuchet MS, Roboto, Ubuntu, sans-serif'
    toolTip.style.borderRadius = '0.125rem';
    toolTip.style.border = '0.0625rem solid';
    toolTip.style.pointerEvents = 'none';
    toolTip.style.textAlign = 'left';
    toolTip.style.fontSize = '0.5rem';
    toolTip.style.boxSizing = 'border-box';
    toolTip.style.zIndex = '1000';
    toolTip.style.display = 'none';
    toolTip.style.position = 'absolute';
    toolTip.style.background = 'white';
    toolTip.style.color = 'black';
    toolTip.style.borderColor = dadosAdicionais.backgroundColor;
    container.appendChild(toolTip);
    // update tooltip
    this.createdChart.subscribeCrosshairMove(param => {
      if (
        param.point === undefined ||
        !param.time ||
        param.point.x < 0 ||
        param.point.x > container.clientWidth ||
        param.point.y < 0 ||
        param.point.y > container.clientHeight
      ) {
        toolTip.style.display = 'none';
      } else {
        // time will be in the same format that we supplied to setData.
        // thus it will be YYYY-MM-DD
        const dateStr = param.time;
        toolTip.style.display = 'block';
        const data = param.seriesData.get(series);
        const price = data.value !== undefined ? data.value : data.close;
        toolTip.innerHTML = `
        <div style="color: ${dadosAdicionais.backgroundColor}">${dadosAdicionais.label}.
        </div><div style="font-size: ${toolTip.style.fontSize}; color: ${'black'}">
          ${price}
          </div><div style="color: ${'black'}">
          ${dateStr}
          </div>`;
        const coordinate = series.priceToCoordinate(price);
        let shiftedCoordinate = param.point.x - 50;
        if (coordinate === null) {
          return;
        }
        shiftedCoordinate = Math.max(
          0,
          Math.min(container.clientWidth - toolTipWidth, shiftedCoordinate)
        );

        toolTip.style.left = `${((shiftedCoordinate + (dadosAdicionais.leftLabel * 10)) / 16)}rem`;
        toolTip.style.top = '12.5rem'
      }
    });
  }

  hidenData(event) {
    this.dataGrafic[event.dataSetIndex].hidden = !event.dataSet.hidden
    this.createGraphicTrandeView(this.dataGrafic)
  }

  printCam(name: string) {
    const modal = this.modalLoading('Baixando');
    setTimeout(() => {
      this.getImage(document.querySelector(`#${this.idPrint}`)).then((img: any) => {
        if (img) {
          const a = document.createElement('a');
          a.setAttribute('download', name + '.png');
          a.setAttribute('href', img);
          a.click();
          modal.close();
        } else {
          modal.close();
        }
      });
    }, 500);
  }

  async getImage(data) {
    try {
      return await html2canvas(data)
        .then((canvas) => {
          const img = canvas.toDataURL('image/png');
          canvas.remove();
          return Promise.resolve(img);
        })
        .catch((error) => {
          return Promise.resolve(null);
        });
    } catch (error) {
      return Promise.resolve(null);
    }
  }

  modalLoading(str?: string): MatDialogRef<ModalLoadingComponent> {
    return this.dialog.open(ModalLoadingComponent, {
      width: '25rem',
      disableClose: true,
      data: {
        title: `${str || 'Carregando'}`,
      },
    });
  }

  ngOnDestroy() {
    this.unsubscribeAll.next();
    this.unsubscribeAll.complete();
  }
}
