import { Component, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ChipsInterface, ChipsSpicActivatedInterface } from '../../../../../utils/models/miscelanea.interface';

@Component({
  selector: 'chips',
  templateUrl: './chips.component.html',
  styleUrls: ['./chips.component.scss']
})
export class ChipsComponent {

  // @Input() chips: ChipsInterface;

  // @Output() onClose: EventEmitter<any> = new EventEmitter<any>();

  // constructor(private readonly element: ElementRef) {}

  // closeChip() {
  //   this.onClose.emit(this.element);
  //   this.element.nativeElement.remove();
  // }


  @Input() chipsInterface: ChipsInterface;

  @Output() activated: EventEmitter<ChipsSpicActivatedInterface> = new EventEmitter();
  @Output() onClose: EventEmitter<string> = new EventEmitter();

  expanded = false;

  constructor(
    private readonly element: ElementRef
  ) { }


  changeActivated(): void {
    if (this.chipsInterface?.clickable && !this.chipsInterface?.disabled) {
      const active = !this.chipsInterface.active
      this.chipsInterface.active = active;
      this.activated.emit({
        key: this.chipsInterface.key,
        active
      });
    }
  }

  changeClose(event : Event): void {
    if (this.chipsInterface?.close && !this.chipsInterface?.disabled) {
      this.element.nativeElement.remove();
      this.onClose.emit(this.chipsInterface.key);
    }
    event.stopPropagation();
  }

  expandedActivated(){
    this.expanded = !this.expanded;
  }

}
