import { Component, Input } from '@angular/core';

@Component({
  selector: 'divider',
  templateUrl: './divider.component.html',
  styleUrls: ['./divider.component.scss']
})
export class DividerComponent {
  @Input() size: string;
  @Input() position: string;
  @Input() alignment = 'horizontal'

  constructor() { }
}
