import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'legend-grafic',
  templateUrl: './legend-grafic.component.html',
  styleUrls: ['./legend-grafic.component.scss']
})
export class LegendGraficComponent {
  @Input() labels: Array<Object>;

  @Input() width = 'auto' // Largura
  @Input() heigth = 'auto' // Altura
  @Input() direction = 'row' // row ou column
  @Input() position = 'right'; // posição right, left, center
  @Input() enableHidden = true;
  @Input() dataSetName = '';
  @Input() titleGroup = null;
  @Input() listenDbClick = false;

  @Output() hiddenData = new EventEmitter();
  @Output() selectedInDbClick = new EventEmitter();
  constructor() { }

  clickLegend(e, index, label?) {
    this.hiddenData.emit({dataSet: e, dataSetIndex: index, label: label});
    const element = document.getElementById(this.dataSetName + index);
    if (element.classList.contains('disabled-icon')) {
      element.classList.remove('disabled-icon');
    } else {
      element.classList.add('disabled-icon')
    }
  }

  handleDbClickLegend(indexItem: number) {
    if (this.listenDbClick) {
      const limits = [];
      this.labels.forEach((item: any, index) => {
        const element = document.getElementById(this.dataSetName + index);
        if (index === indexItem) {
          limits.push({
            key: item.label,
            hidden: false
          });
          element.classList.remove('disabled-icon');
        } else {
          const element = document.getElementById(this.dataSetName + index);
          element.classList.add('disabled-icon')
          limits.push({
            key: item.label,
            hidden: true
          });
        }
      });
      this.selectedInDbClick.emit(limits);
    }
  }

  getStyle() {
    return this.enableHidden === false ? { 'pointer-events': 'none' } : { 'pointer-events': 'all' };
  }
}
