import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { CheckBoxData, CheckboxInput } from '../../../../../utils/models/input.interface';

@Component({
  selector: 'checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: CheckboxComponent,
    },
  ],
})
export class CheckboxComponent implements ControlValueAccessor {
  @Input() checkbox: CheckboxInput;

  @Output() onChange: EventEmitter<CheckBoxData> = new EventEmitter<CheckBoxData>();

  checkHovered = false;

  onCheckMouseEnter() {
    this.checkHovered = true;
  }

  onCheckMouseLeave() {
    this.checkHovered = false;
  }

  writeValue(value: any): void {
    this.checkbox.checked = value;
  }

  _onChange = (value: any) => {
    return value;
  };

  onTouched = (value: unknown) => {
    return value;
  };

  registerOnChange(onChange: (value: any) => any) {
    this._onChange = onChange;
  }

  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }

  onClick() {
    const data : CheckBoxData = {
      value: !this.checkbox.checked,
      label: this.checkbox.label
    }
    const value = !this.checkbox.checked;
    this._onChange(value);
    this.onChange.emit(data);
  }
}
