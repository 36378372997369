import { Directive, Output, EventEmitter, HostListener, ElementRef, Renderer2 } from '@angular/core';

@Directive({
    selector: '[DragDrop]'
})

export class DragDropDirective {

    @Output() onFileDropped = new EventEmitter<any>();

    constructor(
        private readonly el: ElementRef,
        private readonly renderer: Renderer2
    ) {}

    // Arquivo sobre o input
    @HostListener('dragover', ['$event']) onDragOver(evt: Event) {
        evt.preventDefault();
        evt.stopPropagation();
        this.renderer.addClass(this.el.nativeElement, 'drag-over');
    }

    // Arquivo saiu do input
    @HostListener('dragleave', ['$event']) public onDragLeave(evt: Event) {
        evt.preventDefault();
        evt.stopPropagation();
        this.renderer.removeClass(this.el.nativeElement, 'drag-over');
    }

    // Arquivo solto sobre o input
    @HostListener('drop', ['$event']) public ondrop(evt: any) {
        evt.preventDefault();
        evt.stopPropagation();
        this.renderer.removeClass(this.el.nativeElement, 'drag-over');
        const files = evt.dataTransfer.files;
        if (files.length > 0) {
            this.onFileDropped.emit(files);
        }
    }
}
