import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TextInput, Warning } from './../../../../../utils/models/input.interface';
import { returnColorWarning, returnTextWarning, returnWarningIcon } from '../../../functions/utilities.service';

@Component({
  selector: 'time-input',
  templateUrl: './time-input.component.html',
  styleUrls: ['./time-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: TimeInputComponent,
    },
  ],
})
export class TimeInputComponent implements OnInit, ControlValueAccessor {
  warningIconTimeInput = returnWarningIcon;
  colorWarningTimeInput = returnColorWarning;
  textWarningTimeInput = returnTextWarning;

  selectedHour = '';
  selectedMinute = '';
  mask = [];

  @Input() timerInput: TextInput;
  @Input() customCharacter = 2;


  @Input() warning: Warning = {
    sucess: null,
    error: null,
    info: null,
    alert: null,
    load: null
  };

  @Output() valueEmitter: EventEmitter<string> = new EventEmitter<string>();

  hovered = false;

  constructor() {}

  onMouseEnter() {
    this.hovered = true;
  }

  onMouseLeave() {
    this.hovered = false;
  }

  writeValue(value: any): void {
    this.timerInput.value = value;
  }

  onChange = (value: any) => {
    return value;
  };

  onTouched = (value: unknown) => {
    return value;
  };

  registerOnChange(onChange: (value: any) => any) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }

  ngOnInit(): void {
    if(this.timerInput?.value === undefined || this.timerInput?.value === null) {
      this.timerInput.value = '';
    }

    if(this.customCharacter === 1){
      this.mask = [/\d/, ':', /\d/, /\d/];
    }
      else if(this.customCharacter === 2) {
      this.mask = [/\d/, /\d/, ':', /\d/, /\d/];
    } else if(this.customCharacter === 3) {
      this.mask = [/\d/, /\d/, /\d/, ':', /\d/, /\d/];
    } else if(this.customCharacter === 4 ) {
      this.mask = [/\d/, /\d/, /\d/, /\d/, ':', /\d/, /\d/];
    } else {
      this.mask = [/\d/, /\d/, ':', /\d/, /\d/];
    }
  }

    getLeftStyle(): string {
      if(this.customCharacter === 1){
        return '2.4375rem';
      }
        else if(this.customCharacter === 2) {
        return '3.4375rem';
      } else if(this.customCharacter === 3) {
        return '4rem';
      } else if(this.customCharacter === 4 ) {
        return '4.5625rem';
      }
      return '3.4375rem'
    }

  setNewValue(): void {
    this.onChange(this.timerInput.value);
    this.valueEmitter.emit(this.timerInput.value);
  }

  hasValue(): boolean {
    if (this.timerInput.value !== '') {
      return true;
    } else {
      return false;
    }
  }

  validateHour() {
    if (this.selectedHour !== '') {
      const hour = parseInt(this.selectedHour);
      if (hour < 0 || hour > 23) {
        this.selectedHour = '';
      }
    }
  }

  validateMinute() {
    if (this.selectedMinute !== '') {
      const minute = parseInt(this.selectedMinute);
      if (minute < 0 || minute > 59) {
        this.selectedMinute = '';
      }
    }
  }

  incrementHour() {
    if (this.selectedHour === '') {
      this.selectedHour = '00';
    } else {
      let hour = parseInt(this.selectedHour);
      if (hour < 23) {
        hour++;
        this.selectedHour = hour.toString().padStart(2, '0');
      }
    }
  }

  decrementHour() {
    if (this.selectedHour === '') {
      this.selectedHour = '00';
    } else {
      let hour = parseInt(this.selectedHour);
      if (hour > 0) {
        hour--;
        this.selectedHour = hour.toString().padStart(2, '0');
      }
    }
  }

  incrementMinute() {
    if (this.selectedMinute === '') {
      this.selectedMinute = '00';
    } else {
      let minute = parseInt(this.selectedMinute);
      if (minute < 59) {
        minute++;
        this.selectedMinute = minute.toString().padStart(2, '0');
      }
    }
  }

  decrementMinute() {
    if (this.selectedMinute === '') {
      this.selectedMinute = '00';
    } else {
      let minute = parseInt(this.selectedMinute);
      if (minute > 0) {
        minute--;
        this.selectedMinute = minute.toString().padStart(2, '0');
      }
    }
  }

  doSomething(e){
    e.stopPropagation();
  }
}
