import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';

import { filter } from 'rxjs/operators';

import { Breadcrumb } from '../../../../../utils/models/layout.interface';
import { TranslateLanguageService } from '../../common/translate-language/service/translate-language.service';

@Component({
  selector: 'app-topnavbar-breadcrumb',
  templateUrl: './topnavbar-breadcrumb.component.html',
  styleUrls: ['./topnavbar-breadcrumb.component.scss']
})
export class TopnavbarBreadcrumbComponent implements OnInit {

  static readonly ROUTE_DATA_BREADCRUMB = 'breadcrumb';
  readonly home = {icon: 'pi pi-home', url: 'home'};
  menuItems: Breadcrumb[];
  beforeBreadcrumb = [];
  activeRoute?: Breadcrumb;
  nowLanguage = this.translateLanguageService.returnNowLanguage();

  constructor(
    private readonly router: Router,
    private readonly sactivatedRoute: ActivatedRoute,
    private readonly translateLanguageService: TranslateLanguageService
    ) { }

  ngOnInit(): void {
    this.beforeBreadcrumb = this.createBeforeBreadCrumb(this.sactivatedRoute.snapshot.firstChild.data);
    this.menuItems = this.createBreadcrumbs(this.sactivatedRoute.root, this.beforeBreadcrumb);
    this.translateLanguageService.changeTranslation.subscribe((language: string) => {
      this.nowLanguage = language;
    });
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
        .subscribe(() => {
          this.beforeBreadcrumb = this.createBeforeBreadCrumb(this.sactivatedRoute.snapshot.firstChild.data);
          this.menuItems = this.createBreadcrumbs(this.sactivatedRoute.root, this.beforeBreadcrumb);
       });
  }

  createBeforeBreadCrumb(data: any): any[] {
    let breadcrumbs = [];
    if (data?.breadcrumbHome) {
      breadcrumbs = [{
        label: data.breadcrumbHome.label,
        url: data.breadcrumbHome.url
      }]
    }
    return breadcrumbs;
  }

  createBreadcrumbs(route: ActivatedRoute, breadcrumbs: any[] = [], url = '#') {
    const children: ActivatedRoute[] = route.children;
    for (const child of children) {
      const containCustomRoute = child.snapshot.data?.url && child.snapshot.data?.replaceFather ? true : false;
      const routeURL: string = containCustomRoute ? child.snapshot.data?.url : child.snapshot.url.map(segment => segment.path).join('/') ;
      if (routeURL !== '') {
        url = `${containCustomRoute ? routeURL : `${url}/${routeURL}`}`;
      }
      let label = child.snapshot.data[TopnavbarBreadcrumbComponent.ROUTE_DATA_BREADCRUMB];
      if (!!label) {
        if (label['pt-br'] === '{:name}') {
          const name = child.snapshot.params['name'].replace('#', '/');
          label = {
            'pt-br': name,
            'en-us': name,
            'es-es': name
          };
        }
        breadcrumbs.push({label, url});
      }
      return this.createBreadcrumbs(child, breadcrumbs, url);
    }
    if (breadcrumbs.length > 0) {
      this.activeRoute = breadcrumbs[breadcrumbs.length - 1]
    }
    return breadcrumbs;
  }

  getScenarioName() {
    return sessionStorage.getItem('cenarioName');
  }

  isRouterRoot() {
    return this.router.url === '/';
  }

}
