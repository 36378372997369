import { Component } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material/icon';
import icons from '../utils/constants/layout/icons.json';
import { ThemeService } from './shared/service/theme';
import { Title } from '@angular/platform-browser';
import { TranslateLanguageService } from './shared/components/common/translate-language/service/translate-language.service';
// import theme from '../styles/variables/colors.json'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent {
  iconsArray = icons.icons;
  otherIcons = icons.icons_menor;
  feedbackArray = icons.feedback;
  loadingArray = icons.loading;

  constructor(
    private readonly iconregistry: MatIconRegistry,
    private readonly sanitizer: DomSanitizer,
    private readonly themeService: ThemeService,
    private readonly title: Title,
    private readonly translateLanguageService: TranslateLanguageService
  ) {
    this.iconsArray.forEach(icon => {
      this.iconregistry.addSvgIcon(icon.icon,
        this.sanitizer.bypassSecurityTrustResourceUrl(`assets/icons/${icon.path}`));
    });

    // this.themeService.setTheme(theme)


    this.otherIcons.forEach(icon => {
      this.iconregistry.addSvgIcon(icon.icon,
        this.sanitizer.bypassSecurityTrustResourceUrl(`assets/icons/${icon.path}`));
    });

    this.feedbackArray.forEach(icon => {
      this.iconregistry.addSvgIcon(icon.icon,
        this.sanitizer.bypassSecurityTrustResourceUrl(`assets/icons/${icon.path}`));
    });

    this.loadingArray.forEach(icon => {
      this.iconregistry.addSvgIcon(icon.icon,
        this.sanitizer.bypassSecurityTrustResourceUrl(`assets/icons/${icon.path}`));
    });

    this.handleChangeTitle();
    this.translateLanguageService.changeTranslation.subscribe(() => {
      this.handleChangeTitle();
    });
  }

  handleChangeTitle() {
    this.translateLanguageService.returnTranslate('tituloPagina').subscribe(res => {
      this.title.setTitle(res);
    });
  }
}
