import { Component, Input, OnInit, EventEmitter, Output, ElementRef, Renderer2, HostListener } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { NumberInput, Warning } from '../../../../../utils/models/input.interface';
import { returnColorWarning, returnTextWarning, returnWarningIcon } from '../../../functions/utilities.service';
import { TranslateLanguageService } from '../../common/translate-language/service/translate-language.service';

@Component({
  selector: 'number-input',
  templateUrl: './number-input.component.html',
  styleUrls: ['./number-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: NumberInputComponent,
    }
  ],
})
export class NumberInputComponent implements OnInit, ControlValueAccessor {
  warningIconNumberInput = returnWarningIcon;
  colorWarningNumberInput = returnColorWarning;
  textWarningNumberInput = returnTextWarning;

  @Input() numberInput: NumberInput;

  @Input() warning: Warning = {
    sucess: null,
    error: null,
    info: null,
    alert: null,
    load: null
  };

  showSpinner = false;
  oldValue = 0;

  messageToastr = {
    title: '',
    descriptionMaxValue: '',
    descriptionMinValue: '',
    descriptionValueNotAllowed: ''
  }

  @Output() valueEmitter: EventEmitter<number> = new EventEmitter<number>();

  constructor(
    private readonly toastr: ToastrService,
    private readonly translateLanguageService: TranslateLanguageService
  ) { }

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      event.preventDefault();
    }
  }

  upValue(event) {
    event.stopPropagation();
    const oldValue = this.numberInput.value || 0;
    let newVal = oldValue;
    if (oldValue >= this.numberInput.maximo) {
      this.toastr.show(
        this.messageToastr.descriptionMaxValue,
        this.messageToastr.title, { timeOut: 5000 , messageClass: 'error', titleClass: JSON.stringify({})});
    } else {
      newVal = oldValue + this.numberInput.step;
    }

    this.numberInput.value = newVal;
    this.onChange(this.numberInput.value);
    this.valueEmitter.emit(this.numberInput.value);
  }

  downValue(event) {
    event.stopPropagation();
    const oldValue = this.numberInput.value;
    let newVal = oldValue;
    if (oldValue <= this.numberInput.minimo) {
      this.toastr.show(
        this.messageToastr.descriptionMinValue,
        this.messageToastr.title, { timeOut: 5000 , messageClass: 'error', titleClass: JSON.stringify({})});
    } else {
      newVal = oldValue - this.numberInput.step;
    }

    this.numberInput.value = newVal;
    this.onChange(this.numberInput.value);
    this.valueEmitter.emit(this.numberInput.value);
  }

  writeValue(value: any): void {
    this.numberInput.value = value;
  }

  onChange = (value: any) => {
    return value;
  };

  onTouched = (value: unknown) => {
    return value;
  };

  registerOnChange(onChange: (value: any) => any) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }

  ngOnInit(): void {
    if(this.numberInput.value === undefined) {
      this.numberInput.value = 0;
    }
    this.translateNames();
    this.translateLanguageService.changeTranslation.subscribe((language: string) => {
      this.translateNames();
    });
  }

  translateNames(): void {
    this.translateLanguageService.returnTranslate('campoDeNumero').subscribe(res => {
      this.messageToastr = {
        title: res.mensagem.titulo,
        descriptionMaxValue: res.mensagem.descricaoValorMaximo,
        descriptionMinValue: res.mensagem.descricaoValorMinimo,
        descriptionValueNotAllowed: res.mensagem.descricaoValorNaoPermitido,
      }
    });
  }

  setNewValue(): void {
    if (!this.validValue()) {
      this.numberInput.value = this.oldValue;
    }

    this.onChange(this.numberInput.value);
    this.valueEmitter.emit(this.numberInput.value);
  }

  validValue(): boolean {
    if (this.numberInput.value > this.numberInput.maximo || this.numberInput.value < this.numberInput.minimo) {
      this.toastr.show(
        this.messageToastr.descriptionValueNotAllowed,
        this.messageToastr.title,
        { timeOut: 5000 , messageClass: 'error', titleClass: JSON.stringify({})});
      return false;
    } else {
      return true;
    }
  }

  canShowSpinner() {
    this.showSpinner = !this.showSpinner;
    this.oldValue = this.numberInput.value;
  }
}
