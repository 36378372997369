import { Component, Input, EventEmitter, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { SelectInput, Warning } from '../../../../../utils/models/input.interface';
import { returnColorWarning, returnTextWarning, returnWarningIcon } from '../../../functions/utilities.service';
@Component({
  selector: 'select-input',
  templateUrl: './select-input.component.html',
  styleUrls: ['./select-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: SelectInputComponent,
    },
  ],
})
export class SelectInputComponent implements ControlValueAccessor {
  warningIconSelectInput = returnWarningIcon;
  colorWarningSelectInput = returnColorWarning;
  textWarningSelectInput = returnTextWarning;

  @Input() selectInput: SelectInput;
  @Input() loading = false;
  @Input() warning: Warning = {
    sucess: null,
    error: null,
    info: null,
    alert: null,
    load: null
  };

  valuePage = 1

  @Output() valueEmitter: EventEmitter<any> = new EventEmitter<any>();

  @Output() endScroll: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() page: EventEmitter<number> = new EventEmitter<number>();

  constructor() { }


  writeValue(value: any): void {
    this.selectInput.value = value;
  }

  onChange = (value: any) => {
    return value;
  };

  onTouched = (value: unknown) => {
    return value;
  };

  registerOnChange(onChange: (value: any) => any) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }

  setNewValue() {
    this.onChange(this.selectInput.value);
    this.valueEmitter.emit({option: this.selectInput.value});
  }

  genericButton(value) {
    this.selectInput.value = undefined;
    this.valueEmitter.emit({option: this.selectInput.value});
  }

  onSelectScroll(value) {}

  infiniteScroll() {
    if(!this.loading){
      this.valuePage ++
      this.endScroll.emit(true)
      this.page.emit(this.valuePage)
    }
  }
}
