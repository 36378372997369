import { EventEmitter, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class TranslateLanguageService {
  changeTranslation: EventEmitter<string> = new EventEmitter();

  objLanguage = {
    pt: 'pt-br',
    en: 'en-us',
    es: 'es-es'
  }

  constructor(
    private readonly translate: TranslateService
  ) { }

  returnLocalLanguage() {
    return localStorage.getItem('language');
  }

  handleChangeTranslation(language: string): void {
    localStorage.setItem('language', language);
    this.translate.use(language);
    this.changeTranslation.emit(language);
  }

  returnTranslate(key: string) {
    return this.translate.get(key);
  }

  returnTranslateInstant(key: string): string {
    return this.translate.instant(key);
  }

  returnCurrentLanguage(): string {
    return this.translate.currentLang;
  }

  returnBrowserLanguage(): string {
    return this.translate.getBrowserLang();
  }

  returnNowLanguage(): string {
    const localLanguage = this.returnLocalLanguage();
    if (localLanguage) {
      return localLanguage;
    } else if (this.translate.currentLang) {
      return this.translate.currentLang;
    }
    return 'pt-br';
  }

  verifyLanguangeNowLanguage() {
    const localLanguage = this.returnLocalLanguage();
    if (localLanguage) {
      if (localLanguage !== this.translate.currentLang) {
        this.handleChangeTranslation(localLanguage);
      }
    } else {
      if (!this.translate.currentLang) {
        const browserLang = this.translate.getBrowserLang();
        this.handleChangeTranslation(this.objLanguage[browserLang] ? this.objLanguage[browserLang] : 'pt-br');
      }
    }
  }
}
