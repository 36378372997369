import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'empty-state',
  templateUrl: './empty-state.component.html',
  styleUrls: ['./empty-state.component.scss']
})
export class EmptyStateComponent implements OnInit {

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset).pipe(map(result => result.matches));
  mobile = false

  @Input() icon: string | null;
  @Input() title: string;
  @Input() description: string | null;
  @Input() condensed: boolean;
  @Input() sizeIcon: string;
  @Input() sizeContainer: string;
  @Input() sizeIconMobile: string;
  @Input() sizeContainerMobile: string;

  
  constructor(private readonly breakpointObserver: BreakpointObserver){}

  ngOnInit() {
    this.isHandset$.subscribe((res)=>{
      if(res){
        this.mobile = true
      } else{
        this.mobile = false
      }
    })
  }

}
