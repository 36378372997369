import { Component, Input, OnInit } from '@angular/core';
import { TranslateLanguageService } from './service/translate-language.service';

@Component({
  selector: 'translate-language',
  templateUrl: './translate-language.component.html',
  styleUrls: ['./translate-language.component.scss']
})
export class TranslateLanguageComponent implements OnInit {
  @Input() language = 'pt-br';
  @Input() optionsSelect = [];

  selectInterface;

  constructor(
    private readonly translateLanguageService: TranslateLanguageService
  ) { }

  ngOnInit(): void {
    this.selectInterface = { value: this.language, label: '', placeholder: '', options: this.optionsSelect, notClear: true }
    this.translateLanguageService.verifyLanguangeNowLanguage();

    const nowLanguage = this.translateLanguageService.returnCurrentLanguage();
    if (nowLanguage) {
      this.language = nowLanguage;
    }
  }

  changeLanguage(): void {
    this.translateLanguageService.handleChangeTranslation(this.language);
  }
}
