import { AfterViewChecked, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, NgZone, OnChanges, OnDestroy, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import mermaid from 'mermaid';
import { ButtonClickedFlowchart, ButtonNodeFlowchart, NodeFlowchart } from '../../../../../utils/models/common.interface';

@Component({
  selector: 'flowchart',
  templateUrl: './flowchart.component.html',
  styleUrls: ['./flowchart.component.scss']
})
export class FlowchartComponent implements OnInit, OnChanges, AfterViewChecked, OnDestroy {
  @Input() nodes: NodeFlowchart[];
  @Output() buttonClicked: EventEmitter<ButtonClickedFlowchart> = new EventEmitter<ButtonClickedFlowchart>();
  @Output() clearDetach: EventEmitter<boolean> = new EventEmitter<boolean>();

  flowChart: any;
  stringFlowChart: any = '';
  listernerEvents: boolean;
  hasButtons: boolean;
  mermaidInstance: any;
  defaultValue = true;
  loadingFlowchart: boolean;
  changeDrag = true;
  resizeEventListener: () => void;
  beforeRemValue = 0;
  countListenersEvents = 0;

  constructor(
    private readonly renderer: Renderer2,
    private readonly zone: NgZone,
    private readonly elementRef: ElementRef
  ) {}

  ngOnInit(): void {
    this.mermaidInstance = mermaid.initialize({ startOnLoad: false, maxTextSize: 90000000000000000000000000000000000000000000 });

    this.resizeEventListener = async () => {
      await new Promise(() => {
        setTimeout(() => {
          if (this.changeDrag && this.elementRef.nativeElement.querySelector('.mermaid')) {
            this.createFlowchart(this.nodes, true);
            this.changeDrag = false;
          }
        }, 300);
      });
    };
    window.addEventListener("resize", this.resizeEventListener);
  }

  ngOnChanges(change): void {
    if (change?.nodes) {
      this.hasButtons = (this.nodes.filter(res => res.buttons).length > 0);
      this.createFlowchart(change.nodes.currentValue, !change.nodes.firstChange);

      if (!change.nodes.firstChange) {
        this.listernerEvents = true;
      }
    }
  }

  ngAfterViewChecked() {
    if (this.hasButtons) {
      this.zone.runOutsideAngular(() => {
        if (!this.listernerEvents) {
          this.addEventListenersFlowChart();
        }
      });
    }
  }

  getChildren(array, parentKey) {
    let children = [];
    array.forEach(item => {
      if (item.parent && item.parent.includes(parentKey)) {
        children.push(item);
        children = children.concat(this.getChildren(array, item.key));
      }
    });
    return children;
}

  editWithIsDetach(ids: string[], node: NodeFlowchart, idParents) {
    const findChart = ids.find(id => id.includes(node.key))
    if (findChart) {
      const nodeLayout = document.querySelector(`#${findChart} rect`);
      if (nodeLayout) {
        node?.detach ? nodeLayout.classList.add('detach-element-flowchart') : '';

        if (node?.detach) {
          const parents = this.nodes.map(res => {
            return {
              key: res.key,
              // detach: (node?.detach && res.parent !== undefined && res.parent?.indexOf(node.key) !== -1)
              detach: idParents.includes(res.key)
            };
          });
          parents.forEach(parent => {
            const findParent = ids.find(id => id.includes(parent.key));
            if (findParent && findParent !== findChart) {
              const nodeParentLayout = document.querySelector(`#${findParent} rect`);
              if (nodeParentLayout) {
                parent.detach ? nodeParentLayout.classList.add('detach-parent-element-flowchart') : '';
              }
            }
          });
        }
      }
    }
  }

  changeColorArrows() {
    const idsFlowChartLabel: string[] = []
    document.querySelectorAll('.flowchart-label').forEach(res => {
      idsFlowChartLabel.push(res.getAttribute('id'));
    });
    let idParents = [];
    const nodeDetach = this.nodes.find(res => res?.detach);
    if (nodeDetach) {
      idParents = [...new Set(this.getChildren(this.nodes, nodeDetach.key).map(item => item.key))]
    }
    this.nodes.forEach(item => {
      document.querySelectorAll(`.LS-${item.key}`).forEach((arw: any) => {
        arw.style.stroke = `${item?.detach || idParents.includes(item.key) ? '#1B4693' : '#A9C2EF'}`;
      })
      if (idsFlowChartLabel.length > 0) {
        this.editWithIsDetach(idsFlowChartLabel, item, idParents);
      }
    })
  }

  addEventListenersFlowChart() {
    if (this.nodes.length > 0) {
      this.changeColorArrows();
    }
    const buttonsFlowchart = this.elementRef.nativeElement.querySelectorAll('.js-event-click-btn-flowchart');
    if (buttonsFlowchart.length > 0) {
      buttonsFlowchart.forEach(item => {
        const nodeBtn = this.nodes.find(res => res.key === item.getAttribute('data-id'));
        this.renderer.listen(item, 'click', () => this.onClickButtonFlowchart(item.getAttribute('data-action'), nodeBtn));
      })
      if (this.countListenersEvents <= 1) {
        this.listernerEvents = true;
        this.countListenersEvents++;
      }
    }
  }

  createButtonsFlowchart(buttons: ButtonNodeFlowchart[], key: string): string {
    let btns = '';
    const parents = this.nodes.filter(res => res?.parent && res.parent.indexOf(key) !== -1);
    buttons.forEach(btn => {
      const verifyDisabled = btn.action === 'detach' && parents.length <= 0;
      btns += `
        <button
          class="js-event-click-btn-flowchart ${verifyDisabled ? 'disabled-button-flowchart' : ''} ${btn.class ? btn.class : 'button-flowchart'}"
          data-id="${key}"
          data-action="${btn.action}"
          ${verifyDisabled ? 'disabled' : ''}
        >
          ${btn.title}
          ${btn.tooltip ? `<div>${btn.tooltip}</div>` : ''}
        </button>
      `;
    });
    return btns;
  }

  insertHtmlFlowchart(title: string, node: NodeFlowchart): string {
    let buttons = '';
    if (node.buttons) {
      buttons = this.createButtonsFlowchart(node.buttons, node.key);
    }
    return `
      <div
        class="node-flow-chart ${node?.detach ? 'detach-father-flowchart' : ''}"
      >
        <p><span>${title}</span> <strong class="bg-color-arrow-${node.key}"></strong></p>
        ${buttons ? `<div>${buttons}</div>` : ''}
      </div>
    `;
  }

  onClickButtonFlowchart(action: string, node: NodeFlowchart): void {
    this.buttonClicked.emit({
      action,
      node
    });
  }

  mountObjectFlowchart(nodes: NodeFlowchart[]): string[] {
    let objFlowchart = [
      'flowchart LR'
    ];
    let idParents = []
    const nodeDetach = nodes.find(res => res?.detach);
    if (nodeDetach) {
      idParents = [...new Set(this.getChildren(nodes, nodeDetach.key).map(item => item.key))]
    }
    nodes.forEach(node => {
      if (node.parent) {
        const parents = nodes.filter(res => node.parent.indexOf(res.key) !== -1);
        parents.forEach(parent => {
          objFlowchart.push(`
            ${parent.key}(${this.insertHtmlFlowchart(parent.title, parent)}) ${parent?.detach || idParents.includes(parent.key) ? '----' : '--->'} ${node.key}(${this.insertHtmlFlowchart(node.title, node)})
          `)
        });
      } else {
        objFlowchart.push(`
          ${node.key}(${this.insertHtmlFlowchart(node.title, node)})
        `)
      }
    })
    return objFlowchart;
  }

  createFlowchart(nodes?: NodeFlowchart[], refresh?: boolean) {
    const loadingElement = this.elementRef.nativeElement.querySelector('#div-loading-flowchart');
    if (this.elementRef.nativeElement.querySelector('.mermaid')) {
      if (this.beforeRemValue) {
        loadingElement.style.width = `${this.beforeRemValue}rem`;
      }
      loadingElement.parentNode.classList.remove('initial-loading-flowchart');
      loadingElement.innerHTML = this.elementRef.nativeElement.querySelector('.mermaid').innerHTML;
    } else {
      loadingElement.parentNode.classList.add('initial-loading-flowchart');
    }
    this.loadingFlowchart = true;
    if (refresh) {
      this.elementRef.nativeElement.querySelector('.mermaid').innerHTML = '';
      this.defaultValue = false;
      setTimeout(() => {
        this.defaultValue = true;
      }, 300);
    }
    this.zone.runOutsideAngular(() => {
      setTimeout(() => {
        this.flowChart = this.mountObjectFlowchart(nodes || this.nodes);
        this.stringFlowChart = this.flowChart.join("\n");
        this.elementRef.nativeElement.querySelector('.mermaid').innerHTML = this.stringFlowChart;
        mermaid.init();
        this.listernerEvents = false;
      }, 500);
    });
    setTimeout(() => {
      this.loadingFlowchart = false;
      this.elementRef.nativeElement.querySelector('#div-loading-flowchart').innerHTML = '';
      const svgFlowchart = this.elementRef.nativeElement.querySelector('.mermaid > svg');
      if (svgFlowchart) {
        const remWidth = parseFloat((svgFlowchart.style.maxWidth).replace('px', '')) / 16;
        // svgFlowchart.setAttribute('maxWidth', `${remWidth}rem`);
        // svgFlowchart.setAttribute('width', `${remWidth}rem`);
        svgFlowchart.parentNode.style.width = `${remWidth}rem`;
        this.beforeRemValue = remWidth;
      }
      this.changeDrag = true;
    }, 1200);
  }

  ngOnDestroy(): void {
    window.removeEventListener("resize", this.resizeEventListener);
  }
}
