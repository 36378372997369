import {
    ApplicationRef,
    ComponentFactoryResolver,
    ComponentRef,
    Directive,
    ElementRef,
    EmbeddedViewRef,
    HostListener,
    Injector,
    Input,
} from '@angular/core';
import { TooltipPosition, TooltipTheme } from '../../components/miscelanea/tooltip-induxtry/utils/tooltip.enum';
import { TooltipInduxtryComponent } from '../../components/miscelanea/tooltip-induxtry/tooltip-induxtry.component';


@Directive({
    selector: '[tooltipInduxtry]'
})
export class TooltipDirective {

    @Input() tooltipInduxtry = '';
    @Input() position: TooltipPosition = TooltipPosition.DEFAULT;
    @Input() theme: TooltipTheme = TooltipTheme.DEFAULT;
    @Input() color = '';
    @Input() showDelay = 0;
    @Input() hideDelay = 0;
    idTooltip = 'divTooltip_' + this.generateRandomId(10);

    private componentRef: ComponentRef<any> | null = null;
    private showTimeout?: number;
    private hideTimeout?: number;
    private touchTimeout?: number;

    constructor(
        private readonly elementRef: ElementRef,
        private readonly appRef: ApplicationRef,
        private readonly componentFactoryResolver: ComponentFactoryResolver,
        private readonly injector: Injector
    ) {
    }

    @HostListener('mouseenter')
    onMouseEnter(): void {
        this.initializeTooltip();
    }

    @HostListener('mouseleave')
    onMouseLeave(): void {
        this.setHideTooltipTimeout();
    }

    @HostListener('mousemove', ['$event'])
    onMouseMove($event: MouseEvent): void {

        const screenHeight = window.innerHeight;
        const mousePosition = $event.clientY

        if (this.componentRef !== null && this.position === TooltipPosition.DYNAMIC) {
            const tooltipHeight = document.getElementById(this.idTooltip).offsetHeight
            this.componentRef.instance.left = $event.clientX;
            let newTop = mousePosition;
            if ((mousePosition + tooltipHeight) > screenHeight) {
                newTop -= (mousePosition + tooltipHeight) - (screenHeight - 5);
            }
            this.componentRef.instance.top = newTop;
            this.componentRef.instance.tooltip = this.tooltipInduxtry;
        }
    }

    @HostListener('touchstart', ['$event'])
    onTouchStart($event: TouchEvent): void {
        $event.preventDefault();
        window.clearTimeout(this.touchTimeout);
        this.touchTimeout = window.setTimeout(this.initializeTooltip.bind(this), 500);
    }

    @HostListener('touchend')
    onTouchEnd(): void {
        window.clearTimeout(this.touchTimeout);
        this.setHideTooltipTimeout();
    }

    private generateRandomId(size: number): string {
        let randomString = '';
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        for (let i = 0; i < size; i++) {
          randomString += characters.charAt(Math.floor(Math.random() * characters.length));
        }
        return randomString;
      }

    private initializeTooltip() {
        if (this.componentRef === null) {
            window.clearInterval(this.hideDelay);
            const componentFactory = this.componentFactoryResolver.resolveComponentFactory(TooltipInduxtryComponent);
            this.componentRef = componentFactory.create(this.injector);

            this.appRef.attachView(this.componentRef.hostView);
            const [tooltipDOMElement] = (this.componentRef.hostView as EmbeddedViewRef<any>).rootNodes;

            this.setTooltipComponentProperties();

            document.body.appendChild(tooltipDOMElement);
            this.showTimeout = window.setTimeout(this.showTooltip.bind(this), this.showDelay);
        }
    }

    private setTooltipComponentProperties() {
        if (this.componentRef !== null) {
            this.componentRef.instance.tooltip = this.tooltipInduxtry;
            this.componentRef.instance.position = this.position;
            this.componentRef.instance.theme = this.theme;
            this.componentRef.instance.color = this.color;
            this.componentRef.instance.idTooltip = this.idTooltip;

            const { left, right, top, bottom } = this.elementRef.nativeElement.getBoundingClientRect();

            switch (this.position) {
                case TooltipPosition.BELOW: {
                    this.componentRef.instance.left = Math.round((right - left) / 2 + left);
                    this.componentRef.instance.top = Math.round(bottom);
                    break;
                }
                case TooltipPosition.ABOVE: {
                    this.componentRef.instance.left = Math.round((right - left) / 2 + left);
                    this.componentRef.instance.top = Math.round(top);
                    break;
                }
                case TooltipPosition.RIGHT: {
                    this.componentRef.instance.left = Math.round(right);
                    this.componentRef.instance.top = Math.round(top + (bottom - top) / 2);
                    break;
                }
                case TooltipPosition.LEFT: {
                    this.componentRef.instance.left = Math.round(left);
                    this.componentRef.instance.top = Math.round(top + (bottom - top) / 2);
                    break;
                }
                default: {
                    break;
                }
            }
        }
    }

    private showTooltip() {
        if (this.componentRef !== null) {
            this.componentRef.instance.visible = true;
        }
    }

    private setHideTooltipTimeout() {
        const divTooltip = document.getElementById(this.idTooltip);
        let aux = false;
        if (divTooltip) {
            divTooltip.addEventListener('mouseenter', () => {
                aux = true
            });

            divTooltip.addEventListener('mouseleave', () => {
                this.hideTimeout = window.setTimeout(this.destroy.bind(this), this.hideDelay);
            });
        }

        setTimeout(() => {
            if (!aux) {
                this.hideTimeout = window.setTimeout(this.destroy.bind(this), this.hideDelay);
            }
        }, 200)


    }

    ngOnDestroy(): void {
        this.destroy();
    }

    destroy(): void {
        if (this.componentRef !== null) {
            window.clearInterval(this.showTimeout);
            window.clearInterval(this.hideDelay);
            this.appRef.detachView(this.componentRef.hostView);
            this.componentRef.destroy();
            this.componentRef = null;
        }
    }
}
