import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import {
  Component,
  Input,
  EventEmitter,
  Output,
  OnInit
} from '@angular/core';

import {
  Brand,
  PageTitle,
} from '../../../../../utils/models/layout.interface';
import { Observable } from 'rxjs';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { map } from 'rxjs/operators';
import { TranslateLanguageService } from '../../common/translate-language/service/translate-language.service';


@Component({
  selector: 'app-topnavbar',
  templateUrl: './topnavbar.component.html',
  styleUrls: ['./topnavbar.component.scss'],
})
export class TopnavbarComponent implements OnInit {
  @Input() pageTitles: PageTitle[];
  @Input() title: string;
  @Input() breakpoint: boolean;
  @Input() brand: Brand;
  @Input() link;
  @Output() collapse = new EventEmitter();
  @Output() changeGantt = new EventEmitter();

  @Input() drawer: any;

  fullScreen = false;

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset).pipe(map(result => result.matches));

  nowLanguage = this.translateLanguageService.returnNowLanguage();
  beforeBreadcrumb = [];

  constructor(
    public router: Router,
    private readonly breakpointObserver: BreakpointObserver,
    private readonly translateLanguageService: TranslateLanguageService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.translateLanguageService.changeTranslation.subscribe((language: string) => {
      this.nowLanguage = language;
    });
    this.beforeBreadcrumb = this.createBeforeBreadCrumb(this.route.snapshot.firstChild.data);
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.beforeBreadcrumb = this.createBeforeBreadCrumb(this.route.snapshot.firstChild.data);
      }
    });
  }

  createBeforeBreadCrumb(data: any): any[] {
    let breadcrumbs = [];
    if (data?.breadcrumbHome) {
      breadcrumbs = [{
        label: data.breadcrumbHome.label,
        url: data.breadcrumbHome.url
      }]
    }
    return breadcrumbs;
  }

  selectTitle(path: string): Object {
    if (path.includes('digital-assets')) {
      path = '/digital-twin/:id/:name/digital-assets';
    }
    return this.pageTitles.find((page) => page.path === path)
      ? this.pageTitles.find((page) => page.path === path).name[this.nowLanguage].split('-')
      : null;
  }

  returnTitleTopNavbar(obj: any): string {
    return obj?.length > 0 ? obj[obj.length - 1].trim() : 'Gemminus';
  }

  toggleFullScreen(): void {
    if (!document.fullscreenElement) {
      document.documentElement.requestFullscreen().then(() => {
        this.fullScreen = true;
      });
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen().then(() => {
          this.fullScreen = false;
        });
      }
    }
  }

  sendCollapse() {
    this.collapse.emit(true);
  }

  back() {
  }

  openMenu(){
    this.drawer.toggle()
  }

  handleClick(value1, value2) {
    const indexOf = value2.indexOf(value1);
    const newArray = indexOf !== -1 ? value2.slice(0, indexOf + 1) : value2;

    let resultado = newArray.join('-');
    resultado = resultado.replace(/\s+$/, '');

    let breakpoint: string
    this.pageTitles.forEach((item,index) => {
      if(item.name === resultado){
        breakpoint = item.path
      }
    })
    if(breakpoint){
      this.router.navigate([breakpoint])
    }
  }

}
