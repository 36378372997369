import {
  AfterViewInit,
  Component,
  forwardRef,
  Inject,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { MomentDateAdapter } from '@angular/material-moment-adapter';

import moment, { Moment} from 'moment';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepicker } from '@angular/material/datepicker';
import { HeaderPickerComponent } from '../header-picker/header-picker.component';
import { TimePickerComponent } from '../../time-picker/time-picker.component';
import { CalendarPickerComponent } from '../calendar-picker/calendar-picker.component';

const DATE_YEAR = 'DD/MM/YYYY';
export const YEAR_MODE_FORMATS = {
  parse: {
    dateInput: DATE_YEAR,
  },
  display: {
    dateInput: DATE_YEAR,
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: DATE_YEAR,
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

const datePickerClass = {
  DAY: 'calendar-picker',
  MONTH: 'calendar-picker-month',
  YEAR: 'calendar-picker-year'
}
type modeDatePicker = 'DIA' | '' | null;

@Component({
  selector: 'calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss'],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'pt-br' },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    { provide: MAT_DATE_FORMATS, useValue: YEAR_MODE_FORMATS },
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CalendarComponent),
      multi: true,
    },
  ],
})
export class CalendarComponent implements OnInit, ControlValueAccessor, AfterViewInit {
  headerPicker = HeaderPickerComponent;
  @ViewChild('timePicker') timePicker: TimePickerComponent;
  @ViewChild(MatDatepicker) _picker: MatDatepicker<Moment>;

  @Input() customFormFieldClass = '';
  @Input() label = '';
  @Input() showTime = false;
  @Input() touchUi = false;
  @Input() id: string;

  _max: Moment;
  @Input()
  get max(): Date {
    return this._max ? this._max.toDate() : undefined;
  }
  set max(max: Date) {
    if (max) {
      const momentDate = moment(max);
      this._max = momentDate.isValid() ? momentDate : undefined;
    }
  }

  _min: Moment;
  @Input()
  get min(): Date {
    return this._min ? this._min.toDate() : undefined;
  }
  set min(min: Date) {
    if (min) {
      const momentDate = moment(min);
      this._min = momentDate.isValid() ? momentDate : undefined;
    }
  }

  private _mode: modeDatePicker = '';
  @Input()
  get mode(): 'DIA' | '' | null {
    return this._mode;
  }
  set mode(mode: 'DIA' | '' | null) {
    this._mode = mode;
    this._setupFilter();
  }

  _customFilter: (d: Moment) => boolean;
  _inputCtrl: FormControl = new FormControl();
  _panelClass = datePickerClass.DAY;
  _timePickerMoment = moment();

  onChange = (date: Date) => {};
  onTouched = () => {};
  _takeFocusAway = (datepicker: MatDatepicker<Moment>) => {};

  constructor(private readonly parent: CalendarPickerComponent,
    @Inject(MAT_DATE_FORMATS) private readonly dateFormats) {}

  ngOnInit(): void {
    if (this.showTime) {
      this.dateFormats.display.dateInput = 'DD/MM/YYYY HH:mm';
    } else {
      this.dateFormats.display.dateInput = DATE_YEAR;
    }
  }

  ngAfterViewInit() {
    this._takeFocusAway = this.parent._takeFocusAway;
  }

  writeValue(date: Date): void {
    if (date) {
      const momentDate = moment(date);
      if (momentDate.isValid()) {
        this._inputCtrl.setValue(momentDate, { emitEvent: false });
      }
    }
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this._picker.disabled = isDisabled;
    isDisabled ? this._inputCtrl.disable() : this._inputCtrl.enable();
  }

  _dateChangeHandler(chosenDate: Moment) {
    const data = chosenDate.toDate();
    if (this.showTime) {
      data.setHours(parseInt(this._timePickerMoment.format('HH')));
      data.setMinutes(parseInt(this._timePickerMoment.format('mm')));
    }
    this._inputCtrl.setValue(data, { emitEvent: false });
    this.onChange(data);
    this.onTouched();
  }

  _openDatepickerOnClick(datepicker: MatDatepicker<Moment>) {
    if (!datepicker.opened) {
      this.setPanelClassCalendar(datePickerClass.DAY);
      datepicker.open();
      this.onTouched();
    }
  }

  private _setupFilter() {
    if(this.mode === 'DIA') {
      this._customFilter = (d: Moment) => {
        return !d.day();
      };
    }
  }
  setPanelClassCalendar(viewCalendar) {
    if (this._panelClass === '') {
      this._panelClass = datePickerClass.DAY;
    } else {
      this._panelClass = viewCalendar;
    }
  }

  get panelClass() {
    return this._panelClass;
  }

  get hasTimePicker() {
    if(this._panelClass !== datePickerClass.DAY) {
      return true;
    }
    return false;
  }

  capitalizeMonthCalendar() {
    const contentCalendar = document.getElementsByClassName('mat-calendar-body-cell-content') as HTMLCollectionOf<HTMLElement>;
    Array.from(contentCalendar).forEach((monthCalendar) => {
      monthCalendar.innerText = monthCalendar.innerText.toLowerCase();
      monthCalendar.innerText = monthCalendar.innerText.charAt(0).toUpperCase() + monthCalendar.innerText.slice(1);
    })
  }

  changePanelClassCalendar(event) {
    if (event === 'year') {
      this.setPanelClassCalendar(datePickerClass.MONTH);
      this.capitalizeMonthCalendar();
    } else if (event === 'multi-year') {
      this.setPanelClassCalendar(datePickerClass.YEAR);
    } else {
      this.setPanelClassCalendar(datePickerClass.DAY);
    }
  }

  changeDateTimePicker(time) {
    this._timePickerMoment = time;
  }
}
