import { Component, OnInit } from '@angular/core';
import { FilterPeriodService } from '../../service/filter-period.service';
import moment from 'moment';

@Component({
  selector: 'applied-filters',
  templateUrl: './applied-filters.component.html',
  styleUrls: ['./applied-filters.component.scss']
})
export class AppliedFiltersComponent implements OnInit {
  getPeriod = JSON.parse(this.filterPeriodService.getPeriod()) || null;
  valuePeriod = this.getPeriod && this.getPeriod.startDate !== null && this.getPeriod.endDate !== null ? this.getPeriod : null;
  textPeriod = '';

  constructor(
    private readonly filterPeriodService: FilterPeriodService
  ) { }

  ngOnInit(): void {
    if (this.valuePeriod) {
      this.returnValuePeriod();
    }
    this.filterPeriodService.changePeriod.subscribe(period => {
      this.valuePeriod = period ? JSON.parse(period) : null;
      this.returnValuePeriod();
    })
  }

  returnValuePeriod(): void {
    if (this.valuePeriod) {
      this.textPeriod = `${moment(new Date(this.valuePeriod.startDate)).format("DD/MM/YYYY")} - ${moment(this.valuePeriod.endDate).format("DD/MM/YYYY")}`;
    } else {
      this.textPeriod = '';
    }
  }
}
