export class RowsMerge {
    /**
     * @param data Dados da cada linha da Tabela
     * @param columns Informações das colunas
     */
    compute(data: any[], columns: any[]): Array<Span[]> {
        const spans: Array<Span[]> = this.initSpans(columns.length);
        const spanColumnContexts: SpanColumnContext[] = new Array(columns.length);

        for (const row of data) {
            for (let iCol = 0; iCol < columns.length; iCol++) {
                const column = columns[iCol];
                const spanColumnContext = spanColumnContexts[iCol];

                if (spanColumnContext && this.shouldMergeRow(spanColumnContext.spannedRow, row, column)) {
                    ++spanColumnContext.span.span;
                    spans[iCol].push({ span: 0 });
                } else {
                    const span = { span: 1 };
                    spanColumnContexts[iCol] = { span: span, spannedRow: row };
                    spans[iCol].push(span);
                    spanColumnContexts.slice(iCol + 1).forEach(c => c.spannedRow = {});
                }
            }
        }
        return spans;
    }

    private shouldMergeRow(previousRow: any, currentRow: any, column: any): boolean {
        //Verifica se a coluna possui o merge true
        if (!column?.merge) {
            return false;
        }

        // Verifica se a linha anterior e a linha atual possuem o mesmo "mergeGroupName"
        const previousMergeGroupName = previousRow['mergeGroupName'];
        const currentMergeGroupName = currentRow['mergeGroupName'];
        return previousMergeGroupName === currentMergeGroupName;
    }

    private initSpans(columnsLength: number): Array<Span[]> {
        const spans: Array<Span[]> = [];
        for (let i = 0; i < columnsLength; i++) {
            spans.push([]);
        }
        return spans;
    }
}

export interface Span {
    span: number;
}

interface SpanColumnContext {
    span: Span;
    spannedRow: Object;
}